import * as React from 'react';
import {
    InstructionsContainer,
    Instruction,
    PriceText,
} from './Instructions.styled';

const Instructions = ({ plan }: { plan: number }) => {
    return (
        <InstructionsContainer>
            <PriceText>
                Miesięczny abonament wynosi{' '}
                <b>{plan / 100}zł</b>
            </PriceText>
            <Instruction>
                Podłącz kartę, aby nie martwić się o comiesięczne płatności.
            </Instruction>
            <Instruction>
                Aplikacja będzie pobierać abonament co miesiąc, trzy dni przed
                zakończeniem subskrypcji.
            </Instruction>
            <a
                href={
                    'https://poland.payu.com/wp-content/uploads/sites/14/2020/09/Zbior_wymagan_oraz_zalecen_dotyczacych_uslugi_platnosci_cyklicznych_2020.pdf'
                }
                target={'_blank'}
                rel={'noopener noreferrer'}
            >
                Zbiór Wymagań oraz zaleceń dotyczących usługi Płatności
                cyklicznych
            </a>
            <a
                href={'https://appostol.pl/regulamin-aplikacji-stron-appostol'}
                target={'_blank'}
                rel={'noopener noreferrer'}
            >
                Regulamin aplikacji
            </a>
            <a
                href={'https://appostol.pl/polityka-prywatnosci-plikow-cookies'}
                target={'_blank'}
                rel={'noopener noreferrer'}
            >
                Polityka prywatności
            </a>
        </InstructionsContainer>
    );
};

export default Instructions;
