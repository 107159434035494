import { TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { ErrorMessage } from '@components/_universal/Error/ErrorMessage.styled';
import LogoSvg from '../../../../shared/svgs/logo';
import {
    ConfirmAccountContainer,
    ConfirmButton,
    FormContainer,
} from './ConfirmAccountForm.styled';
import { useHistory as useRouterHistory } from 'react-router-dom';

import useConfirmAccountForm from './useConfirmAccountForm';
import paths from '../../../../shared/paths';

export default () => {
    const {
        verifyResult,
        onSubmit,
        errors,
        register,
        code,
        isValid,
        isSubmitting,
        confirmError,
    } = useConfirmAccountForm();
    const history = useRouterHistory<{ length: string }>();
    return (
        <ConfirmAccountContainer>
            <LogoSvg />
            {verifyResult === 'SUCCESS' && (
                <Alert severity="success" style={{ marginTop: 10 }}>
                    Konto potwierdzone. Możesz teraz założyć parafię.
                </Alert>
            )}
            {verifyResult === 'ERROR' && (
                <Alert severity="error" style={{ marginTop: 50 }}>
                    Wykorzystany lub nieaktualny link. Pamiętaj, że link jest
                    ważny tylko przez godzinę.
                </Alert>
            )}
            <FormContainer>
                <form onSubmit={onSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={!!errors?.parishName?.message}
                        inputRef={register({
                            required: {
                                value: true,
                                message: 'Nazwa parafii jest obowiązkowa',
                            },
                            minLength: 1,
                        })}
                        id="parishName"
                        label="Nazwa Twojej Parafii"
                        name="parishName"
                    />
                    {errors?.parishName?.message && (
                        <ErrorMessage>{errors.parishName.message}</ErrorMessage>
                    )}
                    {!code.length && (
                        <ConfirmButton disabled={!isValid || isSubmitting}>
                            {isSubmitting
                                ? 'Tworzenie parafii...'
                                : 'Stwórz parafię'}
                        </ConfirmButton>
                    )}
                </form>
                {confirmError && (
                    <Alert severity="error" style={{ marginTop: 10 }}>
                        Coś poszło nie tak. Spróbuj jeszcze raz, jeśli problem
                        się powtórzy skontaktuj się ze wsparciem technicznym
                        (support@appostol.pl)
                    </Alert>
                )}
                {code.length > 0 && (
                    <>
                        <Alert severity="success" style={{ marginTop: 10 }}>
                            Twoja Parafia została stworzona. Kod parafii to{' '}
                            <b>{code}</b> (nie musisz go zapisywać, jest
                            dostępny w aplikacji mobilnej). Aby zalogować się do
                            panelu administracyjnego kliknij w poniższy
                            przycisk.
                        </Alert>
                        <ConfirmButton
                            onPress={() => {
                                history.push(paths.login);
                            }}
                        >
                            Przejdź do panelu administracyjnego
                        </ConfirmButton>
                    </>
                )}
            </FormContainer>
        </ConfirmAccountContainer>
    );
};
