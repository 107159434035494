import { useSelector } from 'react-redux';
import { AsyncStatusRegExp } from '@@types/AsyncStatus';
import { getType } from 'typesafe-actions';

export default (action: any) => {
    let status = undefined;

    const matches = AsyncStatusRegExp.exec(getType(action.request));

    if (matches) {
        const [, prefix] = matches;

        status = useSelector(({ _status }) => _status[prefix]);
    }

    return status;
};
