import React from 'react';
import Container from '@material-ui/core/Container';
import RegisterForm from '../components/RegisterForm/RegisterForm';

const RegisterAdminPage = () => {
    return (
        <Container component="main" maxWidth="xs">
            <RegisterForm />
        </Container>
    );
};

export default RegisterAdminPage;
