import React from 'react';
import Container from '@material-ui/core/Container';
import ResetPaswordForm from '../components/ResetPaswordForm/ResetPaswordForm';

const ResetPasswordPage = () => {
    return (
        <Container component="main" maxWidth="xs">
            <ResetPaswordForm />
        </Container>
    );
};

export default ResetPasswordPage;
