import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StarsIcon from '@material-ui/icons/Stars';
import SettingsIcon from '@material-ui/icons/Settings';
import { CalendarToday, ExpandLess, ExpandMore } from '@material-ui/icons';
import * as React from 'react';
import useLeftMenu from './useLeftMenu';
import DayNavigation from './components/DayNavigation';

const LeftMenu = () => {
    const {
        navigateToUsers,
        navigateToCalendar,
        navigateToParishFunctions,
        navigateToProfile,
        navigateToPayments,
        navigateToSettings,
        navigateToPoints,
        handleClick,
        DayNavigationsProps,
        expand,
        role,
    } = useLeftMenu();
    return (
        <List>
            <div>
                <ListItem button onClick={navigateToUsers}>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Użytkownicy" />
                </ListItem>
                {role === 'admin' && (
                    <ListItem button onClick={navigateToPayments}>
                        <ListItemIcon>
                            <ShoppingCartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Płatności" />
                    </ListItem>
                )}
                <ListItem button onClick={navigateToCalendar}>
                    <ListItemIcon>
                        <CalendarToday />
                    </ListItemIcon>
                    <ListItemText primary="Kalendarz" />
                </ListItem>
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Szablony" />
                    {expand ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expand} timeout="auto" unmountOnExit>
                    {DayNavigationsProps().map((props: any, index: number) => (
                        <DayNavigation {...props} key={index} />
                    ))}
                </Collapse>
                <ListItem button onClick={navigateToParishFunctions}>
                    <ListItemIcon>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Funkcje" />
                </ListItem>
                <ListItem button onClick={navigateToProfile}>
                    <ListItemIcon>
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dane do faktury" />
                </ListItem>
                {role === 'admin' &&
                    <ListItem button onClick={navigateToSettings}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Ustawienia" />
                    </ListItem>
                }
                <ListItem button onClick={navigateToPoints}>
                    <ListItemIcon>
                        <StarsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Punkty" />
                </ListItem>
            </div>
        </List>
    );
};

export default LeftMenu;
