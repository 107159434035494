import React from 'react';
import Dashboard from '@domains/DashboardDomain/Dashboard';
import usePayments from './usePayments';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import {
    DueDate,
    PaymentButton,
    Buttons,
    PaymentsContainer,
    SubscriptionText,
    NavigateToProfileContainer,
    NavigateToProfile,
} from './Payments.styled';
import Instructions from '@components/Payments/Instructions/Instructions';
import ConnectedCard from '@components/Payments/ConnectedCard/ConnectedCard';
import AddCard from '@components/Payments/AddCard/AddCard';
import PaymentsTable from '@components/Payments/PaymentsTable/PaymentsTable';
import { ICard } from '@@types/models/Payments';
import Alert from '@material-ui/lab/Alert';

const Payments = () => {
    const {
        cards,
        isPast,
        dueDate,
        getPaymentLink,
        handleDeleteCard,
        setShowAddCard,
        showAddCard,
        payments,
        profileData,
        navigateToProfile,
        plan,
        sendEmail,
        showAlert,
    } = usePayments();
    if (profileData?.name) {
        return (
            <Dashboard>
                <ScopedCssBaseline>
                    <PaymentsContainer>
                        <SubscriptionText>
                            Subskrypcja wspólnoty jest ważna do:
                        </SubscriptionText>
                        <DueDate isPast={isPast}>{dueDate}</DueDate>
                    </PaymentsContainer>
                </ScopedCssBaseline>
                <Instructions plan={plan} />
                {cards?.length === 0 && (
                    <Buttons>
                        <PaymentButton onPress={getPaymentLink}>
                            Opłać 1. miesiąc
                        </PaymentButton>
                        <PaymentButton onPress={() => setShowAddCard(true)}>
                            Podłącz kartę
                        </PaymentButton>
                    </Buttons>
                )}
                {showAddCard && (
                    <AddCard hideCard={() => setShowAddCard(false)}></AddCard>
                )}
                {cards?.map((card: ICard) => (
                    <ConnectedCard
                        key={card.cardNumberMasked}
                        number={card.cardNumberMasked}
                        img={card.brandImageUrl}
                        cardBrand={card.cardBrand}
                        status={card.status}
                        handleDeleteCard={() => handleDeleteCard(card.value)}
                    ></ConnectedCard>
                ))}
                <PaymentsTable
                    payments={payments}
                    sendEmail={sendEmail}
                ></PaymentsTable>
                {showAlert && (
                    <Alert severity="success" style={{ marginTop: '10px' }}>
                        Mail z fakturą został wysłany.
                    </Alert>
                )}
            </Dashboard>
        );
    } else {
        return (
            <Dashboard>
                <ScopedCssBaseline>
                    <NavigateToProfileContainer>
                        <SubscriptionText>
                            Uzupełnij swoje dane do faktury by skorzystać z płatności.
                        </SubscriptionText>
                        <NavigateToProfile onClick={navigateToProfile}>
                            Przejdź do uzupełnienia
                        </NavigateToProfile>
                    </NavigateToProfileContainer>
                </ScopedCssBaseline>
                <PaymentsTable
                    payments={payments}
                    sendEmail={sendEmail}
                ></PaymentsTable>
            </Dashboard>
        );
    }
};

export default Payments;
