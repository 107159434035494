import React from 'react';
import Dashboard from '@domains/DashboardDomain/Dashboard';
import { useStyles } from './ParishFunction.styled';
import useParishFunctions from './useParishFunctions';
import { Grid, Chip, TextField, Button } from '@material-ui/core';
import { ErrorMessage } from '@components/_universal/Error/ErrorMessage.styled';

const ParishFunctions = () => {
    const classes = useStyles();

    const {
        handleDelete,
        userFunctions,
        register,
        isValid,
        onSubmit,
        addFunctionError,
    } = useParishFunctions();

    return (
        <Dashboard>
            <Grid container>
                <Grid item xs={12}>
                    <h2 className={classes.title}>Zarządzaj funkcjami</h2>
                    {userFunctions?.map((func) =>
                        func.deletable ? (
                            <Chip
                                className={classes.chip}
                                key={func.name}
                                label={func.name}
                                onDelete={() =>
                                    handleDelete({ eventFunction: func.name })
                                }
                            />
                        ) : (
                            <Chip
                                className={classes.chip}
                                key={func.name}
                                label={func.name}
                            />
                        ),
                    )}
                    <h3 className={classes.subtitle}>Dodaj nową funkcje</h3>
                    <form className={classes.form} onSubmit={onSubmit}>
                        <TextField
                            required
                            name="eventFunction"
                            id="eventFunction"
                            label="Dodaj funkcje"
                            type="text"
                            inputRef={register({ required: true })}
                        />
                        <Button
                            type="submit"
                            disabled={!isValid}
                            classes={{
                                root: classes.button,
                                disabled: classes.disabledButton,
                            }}
                        >
                            Dodaj
                        </Button>
                    </form>
                    {addFunctionError && (
                        <ErrorMessage>
                            Coś poszło nie tak. Skontaktuj się z Administracją.
                        </ErrorMessage>
                    )}
                </Grid>
            </Grid>
        </Dashboard>
    );
};

export default ParishFunctions;
