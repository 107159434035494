import defaultTheme from '@shared/theme/default.theme';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    flex: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    heading: {
        fontSize: defaultTheme.fontSizes.xl,
        fontWeight: defaultTheme.fontWeight.bold,
    },
    subheading: {
        fontSize: defaultTheme.fontSizes.s,
        fontWeight: defaultTheme.fontWeight.bold,
    },
    eventheading: {
        width: '100%',
        paddingLeft: '15px',
        margin: '10px 0',
        fontSize: defaultTheme.fontSizes.s,
        fontWeight: defaultTheme.fontWeight.bold,
    },
    divider: {
        margin: '50px 0 30px',
        display: 'block',
        width: '100%',
    },
    row: {
        width: '100%',
    },
    button: {
        margin: '20px 0',
        backgroundColor: defaultTheme.colors.secondary,
        color: defaultTheme.colors.white,
        fontWeight: defaultTheme.fontWeight.bold,
        borderRadius: defaultTheme.borderRadius.button,
        '&:hover': {
            color: defaultTheme.colors.secondary,
        },
    },
    primaryButton: {
        margin: '20px 0',
        backgroundColor: defaultTheme.colors.primary,
        color: defaultTheme.colors.white,
        fontWeight: defaultTheme.fontWeight.bold,
        borderRadius: defaultTheme.borderRadius.button,
        '&:hover': {
            color: defaultTheme.colors.primary,
        },
    },
    removeButton: {
        margin: '20px 10px',
        backgroundColor: defaultTheme.colors.red,
        color: defaultTheme.colors.white,
        fontWeight: defaultTheme.fontWeight.bold,
        borderRadius: defaultTheme.borderRadius.button,
        '&:hover': {
            color: defaultTheme.colors.primary,
        },
    },
}));

export const Circle = styled.div<{ background?: string }>`
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: ${({ background }) => (background ? background : 'purple')};
    display: inline-block;
    margin-top: 17px;
    margin-left: 10px;
`;

export const PickerWrapper = styled.div`
    position: absolute;
    z-index: 1;
    left: 156px;
    margin-top: 10px;
`;
