import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncCheckLoggedUser } from '@state/authentication/actions';
import { useHistory } from '@utils/hooks';
import paths from '@shared/paths';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import { __DEV__ } from '@shared/constants';

export default () => {
    const dispatch = useDispatch();

    const loggedUser = useSelector((state) => state.authentication?.user);
    const { navigate } = useHistory();

    const checkLoggedUser = () =>
        dispatch(
            asyncCheckLoggedUser.request(undefined, {
                notification: __DEV__,
                callbacks: {
                    onError: () => {
                        LocalStorageManager.setValue('token', '');
                        navigate(paths.login);
                    },
                },
            }),
        );

    useEffect(() => {
        checkLoggedUser();
    }, []);

    return {
        loggedUser,
    };
};
