export default {
    login: '/login',
    signup: '/rejestracja',
    confirmAccount: '/potwierdz-konto',
    users: '/uzytkownicy',
    payments: '/platnosci',
    dayTemplate: '/szablon',
    calendar: '/wydarzenia/kalendarz',
    parishFunctions: '/funkcje/',
    profile: '/profil/',
    settings: '/ustawienia/',
    resetPassword: '/reset-hasla/',
    points: '/punkty',
};

export const dayPaths: { [key: string]: string } = {
    monday: 'poniedzialek',
    tuesday: 'wtorek',
    wednesday: 'sroda',
    thursday: 'czwartek',
    friday: 'piatek',
    saturday: 'sobota',
    sunday: 'niedziela',
};
