import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import { asyncGetParishUsers, asyncUpdateUserRole } from './actions';

export default createReducer(initialState)
    .handleAction([asyncGetParishUsers.success], (state, action) => ({
        ...state,
        users: action.payload,
    }))
    .handleAction([asyncUpdateUserRole.success], (state, action) => ({
        ...state,
        users: state?.users?.map((user) =>
            user._id === action.payload._id
                ? { ...user, role: action.payload.role }
                : user,
        ),
    }));
