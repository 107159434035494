import React from 'react';
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Event from '@components/Template/Event/Event';
import { useStyles, AddEventTemplate, LoaderContainer } from './Day.styled';
import useDay from './useDay';
import Loader from '@components/Loader/Loader';
import { useActionLoading } from '@utils/hooks';

interface IProps {
    dayString: string;
}

export default ({ dayString }: IProps) => {
    const classes = useStyles();
    const { eventTemplatesValues, addEventTemplate } = useDay();
    const [expanded, setExpanded] = React.useState(-1);
    useActionLoading();
    return useActionLoading() ? (
        <LoaderContainer>
            <Loader />
        </LoaderContainer>
    ) : (
        <>
            {eventTemplatesValues.map((eventTemplateValues, index) => (
                <Accordion expanded={expanded === index} key={index}>
                    <AccordionSummary
                        expandIcon={
                            <div>
                                <ExpandMoreIcon />
                            </div>
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() =>
                            setExpanded(expanded === index ? -1 : index)
                        }
                    >
                        <Typography className={classes.heading}>
                            {`${dayString} ${eventTemplateValues.startHour}`}
                            {`${eventTemplateValues.startHour ? ' - ' : ' '}`}
                            {`${eventTemplateValues.endHour}`}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Event
                                dayString={dayString}
                                values={eventTemplateValues}
                                index={index}
                            />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
            <AddEventTemplate>
                <IconButton
                    color="primary"
                    size={'medium'}
                    onClick={addEventTemplate}
                >
                    <AddCircleIcon />
                </IconButton>
            </AddEventTemplate>
        </>
    );
};
