import { createAsyncAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import { IUser, UserIdWithRole } from '@@types/models/User';

export const asyncGetParishUsers = createAsyncAction(
    '@dashboard/GET_PARISH_USERS:PENDING',
    '@dashboard/GET_PARISH_USERS:SUCCESS',
    '@dashboard/GET_PARISH_USERS:ERROR',
)<[boolean, ActionMeta], [IUser[], ActionMeta], [undefined, ActionMeta]>();

export const asyncUpdateUserRole = createAsyncAction(
    '@dashboard/UPDATE_USER_ROLE:PENDING',
    '@dashboard/UPDATE_USER_ROLE:SUCCESS',
    '@dashboard/UPDATE_USER_ROLE:ERROR',
)<
    [UserIdWithRole, ActionMeta],
    [UserIdWithRole, ActionMeta],
    [undefined, ActionMeta]
>();
