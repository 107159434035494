import * as React from 'react';
import { IconProps } from './types';

export const Close = ({ fill, sizeY }: IconProps) => (
    <svg
        width="1em"
        height={sizeY ? sizeY : '1em'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.7278 12.0072L16.8377 7.91986C17.0347 7.72481 17.0347 7.40906 16.8377 7.21451C16.6411 7.01946 16.3219 7.01946 16.1253 7.21451L12.019 11.2984L7.87469 7.15364C7.67815 6.9566 7.35892 6.9566 7.16237 7.15364C6.96583 7.35117 6.96583 7.67091 7.16237 7.86794L11.3037 12.0097L7.14741 16.143C6.95086 16.3381 6.95086 16.6538 7.14741 16.8484C7.34395 17.0434 7.66318 17.0434 7.85972 16.8484L12.0125 12.7186L16.1403 16.8469C16.3368 17.0439 16.6561 17.0439 16.8526 16.8469C17.0491 16.6493 17.0491 16.3296 16.8526 16.1326L12.7278 12.0072Z"
            fill={(fill && fill[0]) || '#333333'}
        />
    </svg>
);
