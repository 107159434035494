import { EnvType } from '@@types/CommonTypes';
export const __DEV__ = process.env.REACT_APP_API_ENV !== EnvType.production;
export const API_URL = __DEV__
    ? 'https://api.dev.appostol.pl'
    : 'https://api.appostol.pl';

export const PAYU_ID = __DEV__ ? '397447' : '3144480';
export const PAYU_URL = __DEV__
    ? 'https://secure.snd.payu.com/javascript/sdk'
    : 'https://secure.payu.com/javascript/sdk';
export const breakpoints = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
};

export enum fontFamily {
    thin = 'gotham-thin',
    light = 'gotham-light',
    book = 'gotham-book',
    medium = 'gotham-medium',
    bold = 'gotham-bold',
    black = 'gotham-black',
}

export enum fontWeight {
    light = '300',
    regular = '400',
    medium = '500',
    semi_bold = '600',
    bold = '700',
}

export enum fontSize {
    xs = 11,
    s = 12,
    m = 14,
    l = 16,
    xl = 18,
    xxl = 20,
    xxxl = 22,
    _4xl = 30,
}
