import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { asyncGetProfile } from '@state/profile/actions';
import { __DEV__ } from '@shared/constants';

export default () => {
    const profileData = useSelector((state) => state.profile);
    const dispatch = useDispatch();

    const getProfile = () =>
        dispatch(
            asyncGetProfile.request(undefined, {
                notification: __DEV__,
            }),
        );

    useEffect(() => {
        if (!profileData) {
            getProfile();
        }
    }, []);
};
