import React from 'react';
import { TextField } from '@material-ui/core';
import { ResetPasswordContainer, SubmitButton, Header } from './ResetPaswordForm.styled';
import { ErrorMessage } from '@components/_universal/Error/ErrorMessage.styled';
import useResetPaswordForm from './useResetPaswordForm';

export default () => {
    const { register, isValid, error, onSubmit, setPassword, setConfirmPassword } = useResetPaswordForm();

    return (
        <ResetPasswordContainer>
            <Header>Wpisz nowe hasło</Header>
            <form onSubmit={onSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    inputRef={register({ required: true })}
                    id="password"
                    label="Nowe Hasło"
                    type="password"
                    name="password"
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    inputRef={register({ required: true })}
                    fullWidth
                    name="confirmPassword"
                    label="Powtórz hasło"
                    type="password"
                    id="confirmPassword"
                />
                <SubmitButton disabled={!isValid}>Zmień hasło</SubmitButton>
                {error && (
                    <ErrorMessage>
                        {error}
                    </ErrorMessage>
                )}
            </form>
        </ResetPasswordContainer>
    );
};
