import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { PayloadMetaAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import { __DEV__ } from '@shared/constants';
import { AxiosResponse } from 'axios';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import {
    IConfirmAccountResponse,
    ISigninResponse,
} from '@shared/api/authentication/responses';
import authenticationApi from '@shared/api/authentication/authenticationApi';
import {
    IConfirmAccountRequest,
    IResetPasswordRequest,
    ISigninRequest,
    ISignupRequest,
    IVerifyTokenRequest,
} from '@shared/api/authentication/requests';
import { IUser } from '@@types/models/User';

export function* signin(
    action: PayloadMetaAction<string, ISigninRequest, ActionMeta>,
) {
    try {
        const response: AxiosResponse<Omit<
            ISigninResponse,
            'isLoggedIn'
        >> = yield call(authenticationApi.signin, action.payload);
        LocalStorageManager.setValue('token', response.data.token);
        yield put(
            actions.asyncSigninAction.success(
                { ...response.data },
                {
                    message: 'Login Successful',
                    notification: __DEV__,
                },
            ),
        );
        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        action.meta.callbacks?.onError?.();
        yield put(
            actions.asyncSigninAction.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* signup(
    action: PayloadMetaAction<string, ISignupRequest, ActionMeta>,
) {
    try {
        yield call(authenticationApi.signup, action.payload);
        yield put(
            actions.asyncSignupAction.success(undefined, {
                message: 'Signup Successful',
                notification: __DEV__,
            }),
        );
        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        action.meta.callbacks?.onError?.();
        yield put(
            actions.asyncSignupAction.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* verifyToken(
    action: PayloadMetaAction<string, IVerifyTokenRequest, ActionMeta>,
) {
    try {
        yield call(authenticationApi.verifyToken, action.payload);
        yield put(
            actions.asyncVerifyTokenAction.success(undefined, {
                message: 'verify token Successful',
                notification: __DEV__,
            }),
        );
        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        action.meta.callbacks?.onError?.();
        yield put(
            actions.asyncVerifyTokenAction.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* confirmAccount(
    action: PayloadMetaAction<string, IConfirmAccountRequest, ActionMeta>,
) {
    try {
        const response: AxiosResponse<IConfirmAccountResponse> = yield call(
            authenticationApi.confirmAccount,
            action.payload,
        );
        yield put(
            actions.asyncConfirmAccountAction.success(
                { ...response.data },
                {
                    message: 'Confirm account Successful',
                    notification: __DEV__,
                },
            ),
        );
        action.meta.callbacks?.onSuccess?.(response.data.code);
    } catch ({ data: { message, errors, error }, status }) {
        action.meta.callbacks?.onError?.();
        yield put(
            actions.asyncConfirmAccountAction.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* checkLoggedUser(
    action: PayloadMetaAction<string, undefined, ActionMeta>,
) {
    try {
        const response: AxiosResponse<IUser> = yield call(
            authenticationApi.getLoggedUser,
        );
        if (
            response.data.role === 'admin' ||
            response.data.role === 'super_user'
        ) {
            yield put(
                actions.asyncCheckLoggedUser.success(
                    { ...response.data },
                    {
                        message: 'Valid token',
                        notification: __DEV__,
                    },
                ),
            );
            action.meta.callbacks?.onSuccess?.();
        } else {
            yield put(
                actions.asyncCheckLoggedUser.failure(undefined, {
                    message: 'Forbidden',
                    code: 403,
                }),
            );
            action.meta.callbacks?.onError?.();
        }
    } catch ({ data: { message, errors }, status }) {
        yield put(
            actions.asyncCheckLoggedUser.failure(undefined, {
                message,
                code: status,
            }),
        );
        action.meta.callbacks?.onError?.();
    }
}

export function* resetPassword(
    action: PayloadMetaAction<string, IResetPasswordRequest, ActionMeta>,
) {
    try {
        const response: AxiosResponse<void> = yield call(
            authenticationApi.resetPassword,
            action.payload,
        );
        yield put(
            actions.asyncResetPasswordAction.success(undefined, {
                message: 'ResetPassword Successful',
                notification: __DEV__,
            }),
        );
        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        action.meta.callbacks?.onError?.();
        yield put(
            actions.asyncResetPasswordAction.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

function* watchSignin() {
    yield takeLatest(actions.asyncSigninAction.request, signin);
}

function* watchSignup() {
    yield takeLatest(actions.asyncSignupAction.request, signup);
}

function* watchConfirmAccount() {
    yield takeLatest(actions.asyncConfirmAccountAction.request, confirmAccount);
}

function* watchVerifyToken() {
    yield takeLatest(actions.asyncVerifyTokenAction.request, verifyToken);
}

function* watchCheckLoggedUser() {
    yield takeLatest(actions.asyncCheckLoggedUser.request, checkLoggedUser);
}

function* watchResetPassword() {
    yield takeLatest(actions.asyncResetPasswordAction.request, resetPassword);
}

export default function* () {
    yield all([
        watchSignin(),
        watchCheckLoggedUser(),
        watchResetPassword(),
        watchSignup(),
        watchVerifyToken(),
        watchConfirmAccount(),
    ]);
}
