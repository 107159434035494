import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    ConnectedCardContainer,
    CardImg,
    CardNumber,
    UpperSection,
    Error,
} from './ConectedCard.styled';
import { CARD_STATUS } from '@@types/models/Payments';

const useStyles = makeStyles({
    button: {
        marginBottom: 20,
    },
});

const ConnectedCard = ({
    number,
    img,
    cardBrand,
    handleDeleteCard,
    status,
}: any) => {
    const classes = useStyles();
    return (
        <ConnectedCardContainer>
            <UpperSection>
                <CardImg src={img} cardBrand={cardBrand}></CardImg>
                <CardNumber>{number}</CardNumber>
                <IconButton
                    onClick={handleDeleteCard}
                    className={classes.button}
                >
                    <CloseIcon></CloseIcon>
                </IconButton>
            </UpperSection>
            {status === CARD_STATUS.EXPIRED && (
                <Error>Karta straciła ważność. Podepnij nową.</Error>
            )}
        </ConnectedCardContainer>
    );
};

export default ConnectedCard;
