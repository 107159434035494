import { asyncGetSettings, asyncSaveSettings } from '@state/settings/actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { __DEV__ } from '@shared/constants';

export default () => {
    const settingsData = useSelector((state) => state.settings);
    const [alert, setAlert] = useState(false);
    const [approvePoints, setApprovePoints] = useState(
        settingsData?.approvePoints || false,
    );
    const [pointsHoursDeadline, setPointsHoursDeadline] = useState(
        settingsData?.pointsHoursDeadline || 1,
    );
    const [subtractPoints, setSubtractPoints] = useState(
        settingsData?.subtractPoints || false,
    );
    const dispatch = useDispatch();

    const getSettings = () =>
        dispatch(
            asyncGetSettings.request(undefined, {
                notification: __DEV__,
            }),
        );

    useEffect(() => {
        if (!settingsData) {
            getSettings();
        }
    }, []);

    useEffect(() => {
        setApprovePoints(settingsData?.approvePoints || false);
        setPointsHoursDeadline(settingsData?.pointsHoursDeadline || 1);
        setSubtractPoints(settingsData?.subtractPoints || false);
    }, [settingsData]);

    const onSubmit = () => {
        dispatch(
            asyncSaveSettings.request(
                { approvePoints, pointsHoursDeadline, subtractPoints },
                {
                    notification: __DEV__,
                    callbacks: {
                        onSuccess: () => {
                            setAlert(true);
                            setTimeout(() => {
                                setAlert(false);
                            }, 4000);
                        },
                    },
                },
            ),
        );
    };

    return {
        approvePoints,
        setApprovePoints,
        onSubmit,
        alert,
        pointsHoursDeadline,
        setPointsHoursDeadline,
        setSubtractPoints,
        subtractPoints,
    };
};
