import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { PayloadMetaAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import { __DEV__ } from '@shared/constants';
import { AxiosResponse } from 'axios';
import { IUser, UserIdWithRole } from '@@types/models/User';
import dashboardApi from '@shared/api/dashboard/dashboardApi';
import usersApi from '@shared/api/users/usersApi';

export function* getParishUsers(
    action: PayloadMetaAction<string, boolean, ActionMeta>,
) {
    try {
        const response: AxiosResponse<IUser[]> = yield call(
            dashboardApi.getParishUsers,
            action.payload,
        );
        yield put(
            actions.asyncGetParishUsers.success([...response.data], {
                message: 'Got Parish users',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncGetParishUsers.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* updateUserRole(
    action: PayloadMetaAction<string, UserIdWithRole, ActionMeta>,
) {
    try {
        yield call(usersApi.updateUser, {
            _id: action.payload._id,
            role: action.payload.role,
        });
        yield put(
            actions.asyncUpdateUserRole.success(action.payload, {
                message: 'updated user role',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncUpdateUserRole.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

function* watchGetParishUsers() {
    yield takeLatest(actions.asyncGetParishUsers.request, getParishUsers);
}

function* watchUpdateUserRole() {
    yield takeLatest(actions.asyncUpdateUserRole.request, updateUserRole);
}

export default function* () {
    yield all([watchGetParishUsers(), watchUpdateUserRole()]);
}
