import React from 'react';
import Dashboard from '@domains/DashboardDomain/Dashboard';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { StyleWrapper } from './Calendar.styled';
import useCalendar from './useCalendar';

const Calendar = () => {
    const {
        events,
        getPreviousMonth,
        getNextMonth,
        isAlreadyFilled,
        removeTemplateEvents,
        addTemplateEvents,
    } = useCalendar();
    const calendarRef: any = React.createRef();
    return (
        <Dashboard>
            <StyleWrapper>
                <FullCalendar
                    plugins={[dayGridPlugin, bootstrapPlugin, listPlugin]}
                    initialView={'dayGridMonth'}
                    headerToolbar={{
                        center: 'dayGridMonth,listMonth',
                        right: 'prev,next',
                    }}
                    footerToolbar={{
                        center: 'fillMonth',
                    }}
                    locale="pl"
                    firstDay={1}
                    ref={calendarRef}
                    themeSystem={'bootstrap'}
                    buttonText={{
                        today: 'Dzisiaj',
                        dayGridMonth: 'Widok ogólny',
                        listMonth: 'Widok szczegółowy',
                    }}
                    customButtons={{
                        prev: {
                            click: () =>
                                getPreviousMonth(calendarRef.current.getApi()),
                        },
                        next: {
                            click: () =>
                                getNextMonth(calendarRef.current.getApi()),
                        },
                        fillMonth: {
                            text: isAlreadyFilled
                                ? 'Skasuj szablonowe wydarzenia'
                                : 'Załaduj wydarzenia',
                            click: () =>
                                isAlreadyFilled
                                    ? removeTemplateEvents()
                                    : addTemplateEvents(),
                        },
                    }}
                    events={events}
                />
            </StyleWrapper>
        </Dashboard>
    );
};

export default Calendar;
