import React, { useEffect } from 'react';
import { useState } from 'react';
import 'date-fns';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';

interface IProps {
    label: string;
    name: string;
    defaultValue: string;
    onChange: (value: string) => void;
    setError: (error: string) => void;
}

const HourInput = ({
    label,
    name,
    defaultValue,
    onChange,
    setError,
}: IProps) => {
    const date = new Date('11/20/2020 ' + defaultValue);
    const [hour, setHour] = useState<any>(date);
    useEffect(() => {
        setHour(date);
    }, [defaultValue]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                margin="normal"
                name={name}
                id={name}
                ampm={false}
                label={label}
                value={hour}
                onChange={(date) => {
                    setHour(date);
                    setError('');
                    onChange(format(new Date(date as Date), 'H:mm'));
                }}
                okLabel="OK"
                minutesStep={5}
                cancelLabel="Anuluj"
                KeyboardButtonProps={{
                    'aria-label': 'Zmień godzine',
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default HourInput;
