import * as React from 'react';
import * as Styled from './IconManager.styled';
import * as Icons from '@shared/svgs/Icons';
import { IconName, ColorType } from '@@types/CommonTypes';
import { ThemeContext } from 'styled-components';

interface IProps {
    name?: IconName;
    fill?: ColorType[];
    activeFill?: ColorType[];
    isActive?: boolean;
    size: number;
    sizeY?: number;
    rotate?: number;
    className?: string;
    center?: boolean;
}

const IconManager: React.FC<IProps> = ({
    name,
    fill,
    size,
    sizeY,
    rotate,
    className,
    center,
    activeFill,
    isActive,
}) => {
    const Icon = Icons[name || 'Close'];
    const colors: { [key in ColorType]: string } = React.useContext(
        ThemeContext,
    ).colors;
    const iconColor = fill && fill.map((item) => colors[item]);
    const iconActiveColor =
        activeFill && activeFill.map((item) => colors[item]);

    return (
        <Styled.Holder
            className={className}
            size={size}
            rotate={rotate}
            center={center}
        >
            <Icon fill={isActive ? iconActiveColor : iconColor} sizeY={sizeY} />
        </Styled.Holder>
    );
};

export default IconManager;
