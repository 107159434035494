import { palette, global } from './config';

const defaultTheme = {
    colors: {
        primary: palette.mainColor,
        secondary: palette.green,
        hover: palette.blue,
        disable: palette.gray,
        white: palette.white,
        border: palette.gray,
        app_bg: palette.white,
        red: palette.red,
    },
    ...global,
};

export default defaultTheme;
