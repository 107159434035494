import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoginPage } from 'src/domains';
import paths from '@shared/paths';
import PrivateRoute from '@components/PrivateRoute/PrivateRoute';
import UsersList from '@domains/UsersListDomain/UsersList';
import Payments from '@domains/PaymentsDomain/Payments';
import DayTemplate from '@domains/DayTemplatesDomain/DayTemplate';
import ParishFunctions from '@domains/ParishFunctionsDomain/ParishFunctions';
import Calendar from '@domains/CalendarDomain/Calendar';
import Profile from '@domains/ProfileDomain/Profile';
import Settings from '@domains/SettingsDomain/Settings';
import ResetPasswordPage from '@domains/ResetPasswordDomain/ResetPasswordPage/ResetPasswordPage';
import Points from '@domains/PointsDomain/Points';
import RegisterAdminPage from '../domains/RegisterAdminDomain/RegisterAdminPage/RegisterAdminPage';
import ConfirmAccountPage from '../domains/ConfirmAccountDomain/ConfirmAccountPage/ConfirmAccountPage';

const PathRouter = () => {
    return (
        <Switch>
            <Route path={paths.login} exact component={LoginPage} />
            <Route path={paths.signup} exact component={RegisterAdminPage} />
            <Route
                path={`${paths.confirmAccount}/:confirmToken`}
                exact
                component={ConfirmAccountPage}
            />
            <Route
                path={paths.resetPassword}
                exact
                component={ResetPasswordPage}
            />
            <PrivateRoute path={paths.users} exact component={UsersList} />
            <PrivateRoute path={paths.calendar} exact component={Calendar} />
            <PrivateRoute
                path={`${paths.dayTemplate}/:dayString`}
                exact
                component={DayTemplate}
            />
            <PrivateRoute path={paths.payments} exact component={Payments} />
            <PrivateRoute
                path={paths.parishFunctions}
                exact
                component={ParishFunctions}
            />
            <PrivateRoute path={paths.profile} exact component={Profile} />
            <PrivateRoute path={paths.settings} exact component={Settings} />
            <PrivateRoute path={paths.points} exact component={Points} />
            <Route render={() => <Redirect to="/login" />} />
        </Switch>
    );
};

export default PathRouter;
