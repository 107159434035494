import styled from 'styled-components';

export const StyleWrapper = styled.div`
    .fc-toolbar {
        text-transform: capitalize;
    }
    .fc-event-title {
        white-space: normal;
    }
`;
