import { combineReducers } from 'redux';
import _status from './_status/reducer';
import authentication from './authentication/reducer';
import dashboard from './dashboard/reducer';
import payments from './payments/reducer';
import events from './events/reducer';
import templates from './templates/reducer';
import profile from './profile/reducer';
import settings from './settings/reducer';

export default combineReducers({
    _status,
    authentication,
    dashboard,
    payments,
    events,
    templates,
    profile,
    settings,
});
