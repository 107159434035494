import PolishDays from '@@types/PolishDays';
import paths, { dayPaths } from '@shared/paths';
import { useHistory } from '@utils/hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default () => {
    const { navigate } = useHistory();
    const role = useSelector((state) => state.authentication?.user?.role);
    const navigateToUsers = () => {
        navigate(paths.users);
    };

    const navigateToPayments = () => {
        navigate(paths.payments);
    };

    const navigateToDayTemplate = (dayString: string) => {
        navigate(`${paths.dayTemplate}/${dayString}`);
    };

    const navigateToParishFunctions = () => {
        navigate(paths.parishFunctions);
    };

    const navigateToProfile = () => {
        navigate(paths.profile);
    };

    const navigateToCalendar = () => {
        navigate(paths.calendar);
    };

    const navigateToSettings = () => {
        navigate(paths.settings);
    };

    const navigateToPoints = () => {
        navigate(paths.points);
    };

    const DayNavigationsProps = () =>
        [
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
        ].map((dayString: string) => ({
            callback: navigateToDayTemplate,
            path: dayPaths[dayString],
            primaryText: (PolishDays as { [key: string]: string })[dayString],
        }));

    const [expand, setExpand] = useState(false);

    const handleClick = () => {
        setExpand(!expand);
    };

    return {
        navigateToUsers,
        navigateToPayments,
        navigateToParishFunctions,
        navigateToCalendar,
        navigateToProfile,
        navigateToSettings,
        navigateToPoints,
        handleClick,
        DayNavigationsProps,
        expand,
        role,
    };
};
