import { all, fork } from 'redux-saga/effects';
import _status from '@state/_status/saga';
import authenticationSaga from '@state/authentication/saga';
import dashboardSaga from '@state/dashboard/saga';
import paymentsSaga from '@state/payments/saga';
import eventsSaga from '@state/events/saga';
import templatesSaga from '@state/templates/saga';
import profileSaga from '@state/profile/saga';
import settingsSaga from '@state/settings/saga';
import pointsSaga from '@state/points/saga';

export default function* () {
    yield all([
        fork(_status),
        fork(authenticationSaga),
        fork(dashboardSaga),
        fork(paymentsSaga),
        fork(eventsSaga),
        fork(templatesSaga),
        fork(profileSaga),
        fork(settingsSaga),
        fork(pointsSaga),
    ]);
}
