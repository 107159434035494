import { IUser } from '@@types/models/User';
import { ISigninResponse } from '@shared/api/authentication/responses';

export interface IAuthenticationModule {
    token?: string;
    user?: IUser;
}

export type AuthenticationModule = Partial<IAuthenticationModule> | null;

export const initialState: AuthenticationModule = null;
