import { Button } from '@components/_universal';
import { fontWeight } from '@shared/constants';
import { palette } from '@shared/theme/config';
import styled from 'styled-components';
import defaultTheme from '@shared/theme/default.theme';

interface DueDateProps {
    readonly isPast: boolean;
}

export const DueDate = styled.div<DueDateProps>`
    font-size: 20px;
    margin-left: 3px;
    color: ${(props: any) => (props.isPast ? palette.red : palette.green)};
`;

export const PaymentsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
`;

export const SubscriptionText = styled.p`
    font-size: 20px;
    margin-bottom: 0;
`;

export const PaymentButton = styled(Button)`
    background-color: ${defaultTheme.colors.primary};
    color: ${palette.white};
    width: 200px
    padding: 15px 10px;
    margin-top: 20px;
    margin-left: 10px;
    font-weight: ${fontWeight.medium};
    border-radius: ${defaultTheme.borderRadius.button};
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: center;
`;

export const NavigateToProfileContainer = styled.div`
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
`;

export const NavigateToProfile = styled.div`
    margin: 20px 0;
    padding: 10px 5px;
    background-color: ${defaultTheme.colors.primary};
    color: ${defaultTheme.colors.white};
    font-weight: ${defaultTheme.fontWeight.bold};
    border-radius: ${defaultTheme.borderRadius.button};
    transition: 500ms all ease;
    width: 200px;
    &:hover {
        color: ${defaultTheme.colors.primary};
        background-color: rgba(0, 0, 0, 0.04);
    }
`;
