import React from 'react';
import { TextField } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form/dist/types';

interface IProps {
    register: any;
    errors: UseFormMethods['errors'];
    errorString: string;
    label: string;
    name: string;
}

const Input = ({ register, errors, errorString, label, name }: IProps) => {
    return (
        <TextField
            variant="outlined"
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            error={errors[name]}
            helperText={errors[name] ? errorString : null}
            fullWidth
            inputRef={register}
            id={name}
            label={label}
            name={name}
        />
    );
};

export default Input;
