import { createAsyncAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import {
    ICreateEventTemplateBody,
    IDeleteEventTemplateBody,
    ITemplate,
    ITemplateEvent,
    IUpdateEventsBody,
    IUpdateEventTemplateBody,
} from '@@types/models/Template';

export const asyncGetTemplate = createAsyncAction(
    '@templates/GET_TEMPLATE:PENDING',
    '@templates/GET_TEMPLATE:SUCCESS',
    '@templates/GET_TEMPLATE:ERROR',
)<[undefined, ActionMeta], [ITemplate, ActionMeta], [undefined, ActionMeta]>();

export const asyncCreateEventTemplate = createAsyncAction(
    '@templates/CREATE_EVENT_TEMPLATE:PENDING',
    '@templates/CREATE_EVENT_TEMPLATE:SUCCESS',
    '@templates/CREATE_EVENT_TEMPLATE:ERROR',
)<
    [ICreateEventTemplateBody, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncGetEventTemplates = createAsyncAction(
    '@templates/GET_EVENT_TEMPLATES:PENDING',
    '@templates/GET_EVENT_TEMPLATES:SUCCESS',
    '@templates/GET_EVENT_TEMPLATES:ERROR',
)<
    [string, ActionMeta],
    [ITemplateEvent[], ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncUpdateEventTemplate = createAsyncAction(
    '@templates/UPDATE_EVENT_TEMPLATES:PENDING',
    '@templates/UPDATE_EVENT_TEMPLATES:SUCCESS',
    '@templates/UPDATE_EVENT_TEMPLATES:ERROR',
)<
    [IUpdateEventTemplateBody, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncDeleteEventTemplate = createAsyncAction(
    '@templates/DELETE_EVENT_TEMPLATES:PENDING',
    '@templates/DELETE_EVENT_TEMPLATES:SUCCESS',
    '@templates/DELETE_EVENT_TEMPLATES:ERROR',
)<
    [IDeleteEventTemplateBody, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncUpdateEvents = createAsyncAction(
    '@templates/UPDATE_EVENTS:PENDING',
    '@templates/UPDATE_EVENTS:SUCCESS',
    '@templates/UPDATE_EVENTS:ERROR',
)<
    [IUpdateEventsBody, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();
