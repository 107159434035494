import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
    label: string;
    name: string;
    value: number;
    onChange: (value: number) => void;
}

const useStyle = makeStyles((theme) => ({
    numberInput: {
        minWidth: '200px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '30px',
        },
    },
}));

const NumberInput = ({ label, name, value, onChange }: IProps) => {
    const classes = useStyle();
    return (
        <TextField
            className={classes.numberInput}
            id={name}
            name={name}
            defaultValue={value ? null : 0}
            value={value ? value : null}
            label={label}
            onChange={(e) => {
                onChange(+e.target.value);
            }}
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

export default NumberInput;
