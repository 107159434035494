import { Button } from '@components/_universal';
import { fontWeight } from '@shared/constants';
import { palette } from '@shared/theme/config';
import defaultTheme from '@shared/theme/default.theme';
import styled from 'styled-components';

export const Description = styled.p`
    margin-left: 30px;
`;

export const SubmitButton = styled(Button)`
    background-color: ${defaultTheme.colors.primary};
    color: ${palette.white};
    width: 200px
    padding: 15px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    font-weight: ${fontWeight.medium};
    border-radius: ${defaultTheme.borderRadius.button};
`;
