import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { __DEV__ } from '@shared/constants';
import { AxiosResponse } from 'axios';
import { settingsApi } from '@shared/api';
import { ISettings } from '@@types/models/Settings';
import { PayloadMetaAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';

export function* getSettings() {
    try {
        const response: AxiosResponse<ISettings> = yield call(
            settingsApi.getSettings,
        );
        yield put(
            actions.asyncGetSettings.success(response.data, {
                message: 'Got Settings data',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncGetSettings.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* postSettings(
    action: PayloadMetaAction<string, ISettings, ActionMeta>,
) {
    try {
        yield call(settingsApi.postSettings, action.payload);
        yield put(
            actions.asyncSaveSettings.success(undefined, {
                message: 'Saved Profile Data Successfuly',
                notification: __DEV__,
            }),
        );

        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncSaveSettings.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

function* watchGetSettings() {
    yield takeLatest(actions.asyncGetSettings.request, getSettings);
}

function* watchPostSettings() {
    yield takeLatest(actions.asyncSaveSettings.request, postSettings);
    yield takeLatest(actions.asyncSaveSettings.success, getSettings);
}

export default function* () {
    yield all([watchGetSettings(), watchPostSettings()]);
    yield all([watchGetSettings()]);
}
