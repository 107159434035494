import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useStyles } from './ProfileForm.styled';
import useProfileForm from './useProfileForm';
import Alert from '@material-ui/lab/Alert';
import Input from './components/Input';

const ProfileForm = () => {
    const classes = useStyles();

    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        profileData,
        alert,
        validateNip,
    } = useProfileForm();

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Input
                                    register={register({
                                        minLength: 3,
                                        required: true,
                                    })}
                                    errors={errors}
                                    errorString="Wartość wymagana (więcej niż 3 znaki)"
                                    label="Imię i nazwisko / Nazwa firma"
                                    name="name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    register={register({
                                        required: true,
                                        pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                    })}
                                    errors={errors}
                                    errorString="Nie poprawny email lub puste pole"
                                    label="E-mail"
                                    name="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    register={register({
                                        minLength: 10,
                                        maxLength: 10,
                                        validate: validateNip,
                                    })}
                                    errors={errors}
                                    errorString="NIP powinien posiadać 10 cyfr i mieć poprawny format"
                                    label="NIP"
                                    name="nip"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    register={register({ required: true })}
                                    errors={errors}
                                    errorString="Pole adres nie może być puste"
                                    label="Adres"
                                    name="address"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    register={register({
                                        required: true,
                                        pattern: /\d{2}-\d{3}/,
                                    })}
                                    errors={errors}
                                    errorString="Podany kod pocztowy jest błędny lub pusty"
                                    label="Kod Pocztowy"
                                    name="zipCode"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    register={register({ required: true })}
                                    errors={errors}
                                    errorString="Pole miejscowość nie może być puste"
                                    label="Miejscowość"
                                    name="city"
                                />
                            </Grid>
                            <Button
                                type="submit"
                                className={classes.primaryButton}
                            >
                                {profileData && profileData.name
                                    ? 'Aktualizuj'
                                    : 'Dodaj'}
                            </Button>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12}>
                    {alert && (
                        <Alert severity="success">
                            Dane zostały poprawne zapisane.
                        </Alert>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ProfileForm;
