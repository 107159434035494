import { __DEV__ } from '@shared/constants';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    asyncConfirmAccountAction,
    asyncVerifyTokenAction,
} from '../../../../state/authentication/actions';
import { useEffect, useState } from 'react';
import { useActionLoading, useActionStatus } from '../../../../utils/hooks';
import { useForm } from 'react-hook-form';
import { IConfirmAccountRequest } from '../../../../shared/api/authentication/requests';

export default () => {
    const { confirmToken }: { confirmToken: string } = useParams();
    const [verifyResult, setVerifyResult] = useState<'SUCCESS' | 'ERROR' | ''>(
        '',
    );
    const [code, setCode] = useState('');
    const [confirmError, setConfirmError] = useState(false);
    const dispatch = useDispatch();
    const {
        register,
        control,
        handleSubmit,
        clearErrors,
        formState: { isValid, isDirty, errors },
    } = useForm({
        mode: 'all',
    });
    const verifyToken = () =>
        dispatch(
            asyncVerifyTokenAction.request(
                { confirmToken },
                {
                    notification: __DEV__,
                    callbacks: {
                        onSuccess: () => {
                            setVerifyResult('SUCCESS');
                        },
                        onError: () => {
                            setVerifyResult('ERROR');
                        },
                    },
                },
            ),
        );

    const isSubmitting = useActionLoading(asyncConfirmAccountAction);
    const actionStatus = useActionStatus(asyncConfirmAccountAction);
    const onSubmit = handleSubmit(({ parishName }: { parishName: string }) => {
        dispatch(
            asyncConfirmAccountAction.request(
                { parishName, confirmToken },
                {
                    notification: __DEV__,
                    callbacks: {
                        onSuccess: (code) => {
                            setCode(code as string);
                        },
                        onError: () => {
                            setConfirmError(true);
                        },
                    },
                },
            ),
        );
    });

    useEffect(() => {
        verifyToken();
    }, []);
    return {
        verifyResult,
        register,
        control,
        onSubmit,
        errors,
        isValid,
        code,
        isSubmitting,
        isDirty,
        clearErrors,
        signUpErrorCode: actionStatus?.code,
        confirmError,
    };
};
