import { ITemplate, ITemplateEvent } from '@@types/models/Template';

export interface ITemplateModule {
    template: ITemplate;
    templates: ITemplateEvent[];
}

export type TemplateModule = Partial<ITemplateModule> | null;

export const initialState: TemplateModule = null;
