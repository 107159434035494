import EnglishDays from '@@types/EnglishDays';
import PolishDays from '@@types/PolishDays';
import paths, { dayPaths } from '@shared/paths';
import { asyncGetParishUsers } from '@state/dashboard/actions';
import { asyncGetUserFunctions } from '@state/events/actions';
import { asyncGetEventTemplates } from '@state/templates/actions';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { __DEV__ } from '@shared/constants';

export default () => {
    const users = useSelector((state) => state.dashboard?.users);
    const userFunctions = useSelector((state) => state.events?.userFunctions);
    const dispatch = useDispatch();
    const { dayString }: { dayString: string } = useParams();

    const mapDayString = (dayString: string) => {
        switch (dayString) {
            case dayPaths.monday:
                return PolishDays.monday;
            case dayPaths.tuesday:
                return PolishDays.tuesday;
            case dayPaths.wednesday:
                return PolishDays.wednesday;
            case dayPaths.thursday:
                return PolishDays.thursday;
            case dayPaths.friday:
                return PolishDays.friday;
            case dayPaths.saturday:
                return PolishDays.saturday;
            case dayPaths.sunday:
                return PolishDays.sunday;
            default:
                window.location.replace(paths.users);
                return '';
        }
    };

    const getParishUsers = () =>
        dispatch(
            asyncGetParishUsers.request(false, {
                notification: __DEV__,
            }),
        );

    const getUserFunctions = () =>
        dispatch(
            asyncGetUserFunctions.request(undefined, {
                notification: __DEV__,
            }),
        );

    const getTemplates = (day: string) => {
        dispatch(
            asyncGetEventTemplates.request(day, {
                notification: __DEV__,
            }),
        );
    };

    useEffect(() => {
        getParishUsers();
        if (!userFunctions) {
            getUserFunctions();
        }
    }, []);

    useEffect(() => {
        getTemplates(
            (EnglishDays as { [key: string]: string })[
                dayString.toLowerCase()
            ].toLowerCase(),
        );
    }, [dayString]);
    return {
        dayString: mapDayString(dayString),
    };
};
