import { ICalendarEvent, IEvent } from '@@types/models/Events';
import {
    asyncAddTemplateEvents,
    asyncGetEvents,
    asyncRemoveTemplateEvents,
} from '@state/events/actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { __DEV__ } from '@shared/constants';

export default () => {
    const dispatch = useDispatch();
    const [activeDate, setActiveDate] = useState(new Date().valueOf());
    const getEvents = (date = activeDate) =>
        dispatch(
            asyncGetEvents.request(
                { date },
                {
                    notification: __DEV__,
                },
            ),
        );

    const removeTemplateEvents = () => {
        if (
            window.confirm(
                'Czy na pewno chcesz skasować wszystkie wydarzenia oparte na szablonie z tego miesiąca?',
            )
        ) {
            dispatch(
                asyncRemoveTemplateEvents.request(
                    {
                        monthNumber: moment(activeDate).get('month'),
                        year: moment(activeDate).get('year'),
                    },
                    {
                        notification: __DEV__,
                        callbacks: {
                            onSuccess: () => {
                                getEvents(activeDate);
                            },
                        },
                    },
                ),
            );
        }
    };

    const addTemplateEvents = () => {
        if (
            window.confirm(
                'Czy na pewno chcesz uzupełnić miesiąc wydarzeniami opartymi na stworzonym szablonie?',
            )
        ) {
            dispatch(
                asyncAddTemplateEvents.request(
                    {
                        monthNumber: moment(activeDate).get('month'),
                        year: moment(activeDate).get('year'),
                    },
                    {
                        notification: __DEV__,
                        callbacks: {
                            onSuccess: () => {
                                getEvents(activeDate);
                            },
                        },
                    },
                ),
            );
        }
    };

    const mapEventToCalendar = (event: IEvent): ICalendarEvent => ({
        id: event._id,
        start: event.startDate,
        end: event.endDate,
        title: event.name,
        backgroundColor: event.color,
    });

    const getPreviousMonth = (calendarApi: any) => {
        const newActiveDate = moment(activeDate).subtract(1, 'month').valueOf();
        getEvents(newActiveDate);
        setActiveDate(newActiveDate);
        calendarApi.prev();
    };

    const getNextMonth = (calendarApi: any) => {
        const newActiveDate = moment(activeDate).add(1, 'month').valueOf();
        getEvents(newActiveDate);
        setActiveDate(newActiveDate);
        calendarApi.next();
    };

    const events = useSelector((state) => state.events?.events);
    const isAlreadyFilled = events?.some(
        (event) => event.template && !moment(event.endDate).isBefore(moment()),
    );

    useEffect(() => {
        getEvents();
    }, []);
    return {
        events: events?.map((event) => mapEventToCalendar(event)),
        activeDate,
        setActiveDate,
        getEvents,
        isAlreadyFilled,
        getPreviousMonth,
        getNextMonth,
        removeTemplateEvents,
        addTemplateEvents,
    };
};
