import createRequest from '@services/ApiService';
import {
    IConfirmAccountRequest,
    IResetPasswordRequest,
    ISigninRequest,
    ISignupRequest,
    IVerifyTokenRequest,
} from './requests';
import { IConfirmAccountResponse, ISigninResponse } from './responses';

export default {
    signin: (data: ISigninRequest) =>
        createRequest<ISigninResponse>(`/auth/dashboard/signin`, 'POST', data),
    signup: (data: ISignupRequest) =>
        createRequest<undefined>(`/auth/dashboard/signup`, 'POST', data),
    verifyToken: (data: IVerifyTokenRequest) =>
        createRequest<undefined>(`/auth/dashboard/verify_token`, 'POST', data),
    confirmAccount: (data: IConfirmAccountRequest) =>
        createRequest<IConfirmAccountResponse>(
            `/auth/dashboard/confirm_account`,
            'POST',
            data,
        ),
    getLoggedUser: () => createRequest<ISigninResponse>(`/users/me`, 'GET'),
    resetPassword: (data: IResetPasswordRequest) =>
        createRequest<void>('/auth/reset_password', 'POST', data),
};
