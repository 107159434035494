import { palette } from '@shared/theme/config';
import styled from 'styled-components';

export const ConnectedCardContainer = styled.div`
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
`;

export const CardImg = styled.img<{ cardBrand: string }>`
    height: ${(props: any) => (props.cardBrand === 'VISA' ? '16px' : '25px')};
    margin-top: ${(props: any) =>
        props.cardBrand === 'VISA' ? '15px' : '10px'};
`;

export const CardNumber = styled.p`
    font-size: 20px;
    margin-left: 10px;
    margin-top: 10px;
`;

export const Error = styled.p`
    color: ${palette.red};
    font-size: 16px;
    float: left;
`;

export const UpperSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
