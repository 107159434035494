import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { __DEV__ } from '@shared/constants';
import { AxiosResponse } from 'axios';
import { templatesApi } from '@shared/api';
import {
    ICreateEventTemplateBody,
    IDeleteEventTemplateBody,
    ITemplate,
    ITemplateEvent,
    IUpdateEventsBody,
    IUpdateEventTemplateBody,
} from '@@types/models/Template';
import { PayloadMetaAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';

export function* getTemplate() {
    try {
        const response: AxiosResponse<ITemplate> = yield call(
            templatesApi.getTemplate,
        );
        yield put(
            actions.asyncGetTemplate.success(response.data, {
                message: 'Got template',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncGetTemplate.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* createTemplate(
    action: PayloadMetaAction<string, ICreateEventTemplateBody, ActionMeta>,
) {
    try {
        yield call(templatesApi.postTemplate, action.payload);
        yield put(
            actions.asyncCreateEventTemplate.success(undefined, {
                message: 'template created',
                notification: __DEV__,
            }),
        );
        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncCreateEventTemplate.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* updateTemplate(
    action: PayloadMetaAction<string, IUpdateEventTemplateBody, ActionMeta>,
) {
    try {
        yield call(templatesApi.updateTemplate, action.payload);
        yield put(
            actions.asyncUpdateEventTemplate.success(undefined, {
                message: 'template updated',
                notification: __DEV__,
            }),
        );
        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncUpdateEventTemplate.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* getTemplates(
    action: PayloadMetaAction<string, string, ActionMeta>,
) {
    try {
        const response: AxiosResponse<ITemplateEvent[]> = yield call(
            templatesApi.getTemplates,
            action.payload,
        );
        yield put(
            actions.asyncGetEventTemplates.success(response.data, {
                message: 'template created',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncGetEventTemplates.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* deleteTemplate(
    action: PayloadMetaAction<string, IDeleteEventTemplateBody, ActionMeta>,
) {
    try {
        yield call(templatesApi.deleteTemplate, action.payload);
        yield put(
            actions.asyncDeleteEventTemplate.success(undefined, {
                message: 'template deleted',
                notification: __DEV__,
            }),
        );
        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncDeleteEventTemplate.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* updateEvents(
    action: PayloadMetaAction<string, IUpdateEventsBody, ActionMeta>,
) {
    try {
        yield call(templatesApi.updateEvents, action.payload);
        yield put(
            actions.asyncUpdateEvents.success(undefined, {
                message: 'events updated',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncUpdateEvents.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

function* watchGetTemplate() {
    yield takeLatest(actions.asyncGetTemplate.request, getTemplate);
}

function* watchCreateTemplate() {
    yield takeLatest(actions.asyncCreateEventTemplate.request, createTemplate);
}

function* watchGetTemplates() {
    yield takeLatest(actions.asyncGetEventTemplates.request, getTemplates);
}

function* watchUpdateTemplate() {
    yield takeLatest(actions.asyncUpdateEventTemplate.request, updateTemplate);
}

function* watchDeleteTemplate() {
    yield takeLatest(actions.asyncDeleteEventTemplate.request, deleteTemplate);
}

function* watchUpdateEvents() {
    yield takeLatest(actions.asyncUpdateEvents.request, updateEvents);
}

export default function* () {
    yield all([
        watchGetTemplate(),
        watchCreateTemplate(),
        watchGetTemplates(),
        watchUpdateTemplate(),
        watchDeleteTemplate(),
        watchUpdateEvents(),
    ]);
}
