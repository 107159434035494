import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import { asyncGetSubscription } from './actions';

export default createReducer(initialState).handleAction(
    [asyncGetSubscription.success],
    (state, action) => ({
        ...state,
        ...action.payload,
    }),
);
