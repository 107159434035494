import React from 'react';
import { BlockPicker, ColorResult } from 'react-color';

interface IProps {
    color: string;
    updateColor: (color: string) => void;
    handleColorPickerVisibility: () => void;
}

const ColorInput = ({
    color,
    updateColor,
    handleColorPickerVisibility,
}: IProps) => {
    const colors: string[] = [
        '#FFD23D',
        '#FFAD46',
        '#F45995',
        '#B99AFF',
        '#6C2440',
        '#74D8FF',
        '#4986E7',
        '#B3EFD3',
        '#1DF290',
        '#399DB2',
    ];
    const handleChange = (color: ColorResult) => {
        updateColor(color.hex);
        handleColorPickerVisibility();
    };

    return (
        <BlockPicker
            color={color}
            colors={colors}
            onChangeComplete={handleChange}
        />
    );
};

export default ColorInput;
