import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import { asyncCheckLoggedUser, asyncSigninAction } from './actions';

export default createReducer(initialState)
    .handleAction([asyncSigninAction.success], (state, action) => ({
        ...state,
        ...action.payload,
    }))
    .handleAction([asyncCheckLoggedUser.success], (state, action) => ({
        ...state,
        user: action.payload,
    }));
