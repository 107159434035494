import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
    asyncDeleteCard,
    asyncGetPaymentLink,
    asyncSendEmail,
} from '@state/payments/actions';
import { asyncGetProfile } from '@state/profile/actions';
import moment from 'moment';
import 'moment/locale/pl';
import paths from '@shared/paths';
import { useHistory } from '@utils/hooks';
import { __DEV__ } from '@shared/constants';

moment.locale('pl');

export default () => {
    const dispatch = useDispatch();
    const [showAddCard, setShowAddCard] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const cards = useSelector((state) => state.payments?.cards || []);
    const sendEmail = (paymentId: string) =>
        dispatch(
            asyncSendEmail.request(paymentId, {
                notification: __DEV__,
                callbacks: {
                    onSuccess: () => {
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 1000 * 10);
                    },
                },
            }),
        );
    const getPaymentLink = () =>
        dispatch(
            asyncGetPaymentLink.request(undefined, {
                notification: __DEV__,
            }),
        );

    const handleDeleteCard = (token: string) =>
        window.confirm('Czy na pewno chcesz odpiąć swoją kartę?') &&
        dispatch(asyncDeleteCard.request({ token }, { notification: __DEV__ }));

    const payments = useSelector((state) => state.payments);

    const dueDate = moment(payments?.parish?.dueDate).format(
        'Do MMMM YYYY, GG:mm:ss',
    );

    const isPast = moment(payments?.parish?.dueDate).isBefore(new Date());

    const profileData = useSelector((state) => state.profile);

    const getProfile = () =>
        dispatch(
            asyncGetProfile.request(undefined, {
                notification: __DEV__,
            }),
        );

    useEffect(() => {
        if (!profileData) {
            getProfile();
        }
    }, []);

    const { navigate } = useHistory();
    const navigateToProfile = () => {
        navigate(paths.profile);
    };

    return {
        dueDate,
        isPast,
        getPaymentLink,
        handleDeleteCard,
        setShowAddCard,
        showAddCard,
        payments: useSelector((state) => state.payments?.payments) || [],
        plan: useSelector((state) => state.profile?.plan) || 0,
        cards,
        profileData,
        navigateToProfile,
        sendEmail,
        showAlert,
    };
};
