import { useForm } from 'react-hook-form';
import { ProfileData } from '@@types/models/ProfileData';
import { useDispatch, useSelector } from 'react-redux';
import { asyncAddProfile } from '@state/profile/actions';
import { useEffect, useState } from 'react';
import { __DEV__ } from '@shared/constants';

export default () => {
    const dispatch = useDispatch();
    const profileData = useSelector((state) => state.profile) || undefined;
    const { register, handleSubmit, control, errors, setValue } = useForm();
    const [alert, setAlert] = useState(false);

    const onSubmit = (data: ProfileData) => {
        if (data) {
            dispatch(
                asyncAddProfile.request(data, {
                    notification: __DEV__,
                    callbacks: {
                        onSuccess: () => {
                            setAlert(true);
                            setTimeout(() => {
                                setAlert(false);
                            }, 4000);
                        },
                    },
                }),
            );
        }
    };

    const validateNip = (nip: string) => {
        if (nip.length > 0) {
            const checksum =
                (6 * parseInt(nip[0]) +
                    5 * parseInt(nip[1]) +
                    7 * parseInt(nip[2]) +
                    2 * parseInt(nip[3]) +
                    3 * parseInt(nip[4]) +
                    4 * parseInt(nip[5]) +
                    5 * parseInt(nip[6]) +
                    6 * parseInt(nip[7]) +
                    7 * parseInt(nip[8])) %
                11;
            return parseInt(nip[9]) === checksum;
        } else {
            return true;
        }
    };

    useEffect(() => {
        setValue('name', profileData?.name);
        setValue('city', profileData?.city);
        setValue('email', profileData?.email);
        setValue('address', profileData?.address);
        setValue('zipCode', profileData?.zipCode);
        setValue('nip', profileData?.nip);
    }, [profileData]);

    return {
        register,
        handleSubmit,
        control,
        errors,
        onSubmit,
        profileData,
        alert,
        validateNip,
    };
};
