import React from 'react';
import useRegisterForm from './useRegisterForm';
import { TextField } from '@material-ui/core';
import LogoSvg from '@shared/svgs/logo';
import {
    RegisterButton,
    RegisterContainer,
    InstructionHeader,
    FormContainer,
} from './RegisterForm.styled';
import { ErrorMessage } from '@components/_universal/Error/ErrorMessage.styled';
import { Alert } from '@material-ui/lab';

export default () => {
    const {
        register,
        isValid,
        onSubmit,
        signUpErrorCode,
        success,
        errors,
        isSubmitting,
    } = useRegisterForm();
    return (
        <RegisterContainer>
            <LogoSvg />
            <InstructionHeader>
                Wypełnij formularz, aby założyć konto opiekuna. Po założeniu
                konta, będziesz mógł zalogować się na nie w aplikacji oraz w web
                panelu.
            </InstructionHeader>
            <FormContainer>
                <form onSubmit={onSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={!!errors?.name?.message}
                        inputRef={register({
                            required: {
                                value: true,
                                message: 'Imię jest obowiązkowe',
                            },
                            minLength: 1,
                        })}
                        id="name"
                        label="Imię"
                        name="name"
                    />
                    {errors?.name?.message && (
                        <ErrorMessage>{errors.name.message}</ErrorMessage>
                    )}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={!!errors?.surname?.message}
                        inputRef={register({
                            required: {
                                value: true,
                                message: 'Nazwisko jest obowiązkowe',
                            },
                            minLength: 1,
                        })}
                        id="surname"
                        label="Nazwisko"
                        name="surname"
                    />
                    {errors?.surname?.message && (
                        <ErrorMessage>{errors.surname.message}</ErrorMessage>
                    )}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={!!errors?.email?.message}
                        inputRef={register({
                            required: {
                                value: true,
                                message: 'Adres e-mail jest obowiązkowy',
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Niepoprawny adres e-mail',
                            },
                            minLength: 1,
                        })}
                        id="email"
                        label="E-mail"
                        name="email"
                    />
                    {errors?.email?.message && (
                        <ErrorMessage>{errors.email.message}</ErrorMessage>
                    )}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={!!errors?.phone?.message}
                        inputRef={register({
                            required: {
                                value: true,
                                message: 'Numer telefonu jest obowiązkowy',
                            },
                            minLength: 1,
                        })}
                        id="phone"
                        label="Nr telefonu"
                        name="phone"
                    />
                    {errors?.phone?.message && (
                        <ErrorMessage>{errors.phone.message}</ErrorMessage>
                    )}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        error={!!errors?.password?.message}
                        inputRef={register({
                            required: {
                                value: true,
                                message: 'Hasło jest obowiązkowe',
                            },
                        })}
                        fullWidth
                        name="password"
                        label="Hasło"
                        type="password"
                        id="password"
                    />
                    {errors?.password?.message && (
                        <ErrorMessage>{errors.password.message}</ErrorMessage>
                    )}
                    {!success && (
                        <RegisterButton disabled={!isValid || isSubmitting}>
                            {isSubmitting
                                ? 'Tworzenie konta...'
                                : 'Zarejestruj się'}
                        </RegisterButton>
                    )}
                    {errors.length > 0 && (
                        <Alert severity="error" style={{ marginTop: 10 }}>
                            {errors[0].message}
                        </Alert>
                    )}
                    {signUpErrorCode && (
                        <Alert severity="error" style={{ marginTop: 10 }}>
                            {signUpErrorCode === 400
                                ? 'Ten adres e-mail jest już w użyciu'
                                : 'Coś poszło nie tak. Spróbuj jeszcze raz, jeśli problem się powtórzy skontaktuj się ze wsparciem technicznym (support@appostol.pl)'}
                        </Alert>
                    )}
                    {success && (
                        <Alert severity="success" style={{ marginTop: 10 }}>
                            Konto zostało stworzone. Sprawdź swoją skrzynkę
                            pocztową.
                        </Alert>
                    )}
                </form>
            </FormContainer>
        </RegisterContainer>
    );
};
