import React, { useState } from 'react';
import { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { IUser } from '@@types/models/User';
import { ITemplateUser } from '@@types/models/Template';

interface IProps {
    data: any; //this needs to be typed correctly
    noOptionText: string;
    placeholder: string;
    helperText: string;
    shrink?: boolean;
    callback?: any;
    type: string;
    user?: ITemplateUser;
    userIndex?: number;
    defaultValue: string[];
    onChange: (user: IUser, eventFunction: string[]) => void;
    getOptionLabel: (option: any) => string;
}

const useStyle = makeStyles((theme) => ({
    select: {
        marginBottom: '15px',
        width: '90%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '30px',
        },
    },
}));

const MultipleChoice = ({
    data,
    noOptionText,
    placeholder,
    helperText,
    shrink,
    defaultValue,
    user,
    onChange,
    getOptionLabel,
}: IProps) => {
    const classes = useStyle();
    let selectClass;
    if (shrink) {
        selectClass = classes.select;
    }
    const [eventFunction, setEventFunction] = useState([]);
    useEffect(() => {
        setEventFunction(defaultValue as any);
    }, [defaultValue]);
    return (
        <Autocomplete
            multiple
            className={selectClass}
            onChange={(_, value: any) => {
                onChange(user as any, value);
                setEventFunction(value);
            }}
            options={data}
            value={eventFunction}
            noOptionsText={noOptionText}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label=""
                    placeholder={placeholder}
                    helperText={helperText}
                />
            )}
        />
    );
};

export default MultipleChoice;
