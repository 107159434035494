import { AsyncStatusType, AsyncStatusRegExp } from '@@types/AsyncStatus';
import { PayloadMetaAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import { take } from 'redux-saga/effects';

export default function* () {
    while (true) {
        const action: PayloadMetaAction<
            string,
            unknown,
            ActionMeta | undefined
        > = yield take('*');
        const matches = AsyncStatusRegExp.exec(action.type);
        const notification = action.meta?.notification;

        if (notification && matches) {
            const [, prefix, status] = matches;
            const actionStatus = status as AsyncStatusType;

            console.log(
                `[EXAMPLE NOTIFICATION]:: action ${prefix}, status: ${actionStatus}, message: ${action.meta?.message}`,
            );
        }
    }
}
