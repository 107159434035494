import React from 'react';
import Dashboard from '@domains/DashboardDomain/Dashboard';
import ProfileForm from '@components/ProfileForm/ProfileForm';
import useProfile from './useProfile';

const Profile = () => {
    useProfile();

    return (
        <Dashboard>
            <ProfileForm />
        </Dashboard>
    );
};

export default Profile;
