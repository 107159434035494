import React from 'react';
import { Grid, Button } from '@material-ui/core';
import HourInput from './Inputs/HourInput';
import Users from './Inputs/Users';
import UserFunctions from './Inputs/UserFunctions';
import NumberInput from './Inputs/NumberInput';
import Notifications from './Inputs/Notifications';
import { useStyles, PickerWrapper, Circle } from './Event.styled';
import useEvent from './useEvent';
import { ITemplateEvent } from '@@types/models/Template';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Prompt from '@components/_universal/Prompt/Prompt';
import ErrorMessage from '@components/_universal/Error/ErrorMessage';
import TextInput from './Inputs/TextInput';
import ColorInput from './Inputs/ColorInput';

interface IProps {
    dayString: string;
    values: ITemplateEvent;
    index: number;
}

const Event = ({ dayString, values, index }: IProps) => {
    const classes = useStyles();

    const {
        eventValues,
        updateStartHour,
        updateEndHour,
        updateNormalPoints,
        updateAdditionalPoints,
        updateAlerts,
        updateUsers,
        updateEventName,
        updateColor,
        alerts,
        users,
        userFunctions,
        updateUserFunctions,
        onSubmit,
        onDelete,
        shouldEditEvents,
        setShouldEditEvents,
        setOpenPropmt,
        openPropmt,
        error,
        setError,
        colorPicker,
        handleColorPickerVisibility,
    } = useEvent(values);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextInput
                    name="eventName"
                    label="Nazwa eventu"
                    onChange={updateEventName}
                    value={eventValues.name}
                    defaultValue={
                        dayString.toLowerCase() === 'niedziela'
                            ? 'Msza niedzielna'
                            : 'Msza codzienna'
                    }
                />
                <Circle
                    onClick={() => {
                        handleColorPickerVisibility();
                    }}
                    background={eventValues.color}
                />
                {colorPicker && (
                    <PickerWrapper>
                        <ColorInput
                            color={eventValues.color}
                            updateColor={updateColor}
                            handleColorPickerVisibility={
                                handleColorPickerVisibility
                            }
                        />
                    </PickerWrapper>
                )}
            </Grid>

            <Grid item xs={6}>
                <HourInput
                    name={'startHour' + index}
                    label="Godzina rozpoczęcia"
                    onChange={updateStartHour}
                    defaultValue={eventValues.startHour}
                    setError={setError}
                />
                {error && <ErrorMessage message={error} />}
            </Grid>

            <Grid item xs={6}>
                <HourInput
                    name={'endHour' + index}
                    onChange={updateEndHour}
                    label="Godzina zakończenia"
                    defaultValue={eventValues.endHour}
                    setError={setError}
                />
            </Grid>

            <Grid item xs={12}>
                <Users
                    data={users}
                    noOptionText="Brak użytkowników"
                    placeholder="Użytkownik"
                    onChange={updateUsers}
                    helperText="Przypisz użytkowników do wydarzenia"
                    defaultValue={eventValues.users}
                />
            </Grid>

            <Grid item xs={12}>
                <h3 className={classes.subheading}>
                    Przypisz funkcję użytkownikom
                </h3>
                <Grid container>
                    {eventValues.users.length === 0 && (
                        <i>Wybierz użytkowników powyżej</i>
                    )}
                    {eventValues.users.map((user: any, userIndex: number) => (
                        <Grid key={userIndex} item xs={12} md={6}>
                            {user.user.name} {user.user.surname}
                            <UserFunctions
                                data={userFunctions}
                                noOptionText="Brak funkcji"
                                placeholder="Funkcja"
                                helperText="Przypisz funkcje do uzytkownika"
                                shrink
                                defaultValue={user.eventFunction}
                                onChange={updateUserFunctions}
                                type="functions"
                                user={user}
                                userIndex={userIndex}
                                getOptionLabel={(userFunctions) =>
                                    userFunctions
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <h3 className={classes.subheading}>
                    Określ ilość punktów za udział w wydarzeniu
                </h3>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <NumberInput
                            label="Udział obowiązkowy"
                            name={'pointsNormal'}
                            value={eventValues.points.normal}
                            onChange={updateNormalPoints}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberInput
                            label="Udział nadobowiązkowy"
                            name={'pointsAdditional'}
                            value={eventValues.points.additional}
                            onChange={updateAdditionalPoints}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <h3 className={classes.subheading}>Powiadomienia</h3>
                <Notifications
                    alerts={alerts}
                    noOptionText="Brak powiadomień"
                    placeholder="Powiadomienia"
                    helperText="Przypisz powiadomienia do wydarzenia"
                    name={'alerts'}
                    defaultValue={eventValues.alerts}
                    onChange={updateAlerts}
                />
            </Grid>
            {eventValues._id && (
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={shouldEditEvents}
                                onChange={() => setOpenPropmt(true)}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Zaktualizuj wszystkie stworzone wydarzenia"
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <Button
                    type="submit"
                    className={classes.primaryButton}
                    onClick={onSubmit}
                >
                    {eventValues._id ? 'Nadpisz szablon' : 'Stwórz szablon'}
                </Button>
                {eventValues._id && (
                    <Button
                        type="submit"
                        className={classes.removeButton}
                        onClick={onDelete}
                    >
                        Usuń szablon
                    </Button>
                )}
            </Grid>
            <Prompt
                open={openPropmt}
                copy="Potwierdzenie spowoduje zaktualizowanie wszystkich
                        przyszłych wydarzeń stworzonych na tym szablonie."
                title="Nadpisz wszystkie przyszle wydarzenia?"
                setOpenPropmt={setOpenPropmt}
                shouldEditEvents={shouldEditEvents}
                setShouldEditEvents={setShouldEditEvents}
            />
        </Grid>
    );
};

export default Event;
