import { createAsyncAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import { ISettings } from '@@types/models/Settings';

export const asyncSaveSettings = createAsyncAction(
    '@settings/SAVE_SETTINGS:PENDING',
    '@settings/SAVE_SETTINGS:SUCCESS',
    '@settings/SAVE_SETTINGS:ERROR',
)<[ISettings, ActionMeta], [undefined, ActionMeta], [undefined, ActionMeta]>();

export const asyncGetSettings = createAsyncAction(
    '@settings/GET_SETTINGS:PENDING',
    '@settings/GET_SETTINGS:SUCCESS',
    '@settings/GET_SETTINGS:ERROR',
)<[undefined, ActionMeta], [ISettings, ActionMeta], [undefined, ActionMeta]>();
