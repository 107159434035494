import { fontFamily } from '@shared/constants';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyled = createGlobalStyle`
    ${normalize};
    
    * {
        box-sizing: border-box;
    }

    html {
        height: 100%;
    }
    
    body {
        height: 100%;
        margin: 0;
        font-family: 'Gotham';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    #root {
        height: 100%;
    }
    
    code {
        font-family: 'Lato', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }
    
    svg {
      transition: all .5s;
      path, circle, rect {
        transition: all .5s ;
      }
    }
    @font-face {
        font-family: ${fontFamily.thin};
        src: local('Gotham'),
            url('@assets/fonts/Gotham/Gotham-Thin.otf') format('opentype');
        }
    
    @font-face {
        font-family: ${fontFamily.light};
        src: local('Gotham'),
            url('@assets/fonts/Gotham/Gotham-Light.otf') format('opentype');
        }
    
    @font-face {
        font-family: ${fontFamily.medium};
        src: local('Gotham'),
            url('@assets/fonts/Gotham/Gotham-Medium.otf') format('opentype');
        }
    
    @font-face {
        font-family: ${fontFamily.book};
        src: url('@assets/fonts/Gotham/Gotham-Book.otf') format('opentype');
        }
    
    @font-face {
        font-family: ${fontFamily.bold};
        src: local('Gotham'),
            url('@assets/fonts/Gotham/Gotham-Bold.otf') format('opentype');
        }

`;
