import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import { asyncGetSettings } from './actions';

export default createReducer(initialState).handleAction(
    [asyncGetSettings.success],
    (state, action) => ({
        ...state,
        ...action.payload,
    }),
);
