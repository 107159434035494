import * as React from 'react';

function LogoSvg(props: any) {
    return (
        <svg
            width={131}
            height={69.979}
            viewBox="0 0 131 69.979"
            style={{ cursor: 'pointer' }}
            onClick={() => {
                window.location.href = '/';
            }}
            {...props}
        >
            <defs>
                <linearGradient
                    id="prefix__a"
                    x1={0.5}
                    x2={0.5}
                    y2={1}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#918aff" />
                    <stop offset={0.624} stopColor="#645aff" />
                    <stop offset={1} stopColor="#6359ff" />
                </linearGradient>
                <linearGradient
                    id="prefix__b"
                    x1={0.5}
                    y1={-0.139}
                    x2={0.5}
                    y2={0.64}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#9b95ff" />
                    <stop offset={1} stopColor="#4f44ff" />
                </linearGradient>
            </defs>
            <g data-name="Group 881">
                <g data-name="Group 880">
                    <g data-name="Group 821">
                        <path
                            data-name="Path 1977"
                            d="M149.761 26.6h-5.025a.075.075 0 00-.068.039s-1.806 20.129-14.71 25.509c-.057.025 0 .122.079.115 19.95-2.4 20.215-17.276 20.108-25.663z"
                            transform="translate(-90.39 -17.062)"
                            fill="url(#prefix__a)"
                        />
                        <path
                            data-name="Path 1978"
                            d="M59.856.043l-5.538 6.606a.075.075 0 00.068.115h5.613V.079a.075.075 0 00-.143-.036z"
                            fill="#928cff"
                        />
                        <path
                            data-name="Path 1979"
                            d="M215.265 52.149c-12.9-5.376-14.728-25.509-14.728-25.509a.072.072 0 00-.065-.039h-5.384c-.111 8.387.158 23.265 20.108 25.663.054.006.13-.072.069-.115z"
                            transform="translate(-132.184 -17.062)"
                            fill="url(#prefix__b)"
                        />
                        <path
                            data-name="Path 1980"
                            d="M68.314 6.649L62.777.043a.075.075 0 00-.143.036v6.685h5.606a.075.075 0 00.072-.115z"
                            fill="#928cff"
                        />
                    </g>
                    <text
                        transform="translate(0 64.979)"
                        fontSize={22}
                        fontFamily="Helvetica"
                        letterSpacing=".082em"
                    >
                        <tspan x={0} y={0}>
                            {'APPOSTOŁ'}
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
}

export default LogoSvg;
