import styled from 'styled-components';
import { Button } from '@components/_universal';
import { palette } from '@shared/theme/config';
import defaultTheme from '@shared/theme/default.theme';
import { fontWeight } from '@shared/constants';

export const RegisterContainer = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-top: 20%;
`;

export const RegisterButton = styled(Button)`
    background-color: ${defaultTheme.colors.primary};
    color: ${palette.white};
    width: 100%;
    padding: 15px 10px;
    margin-top: 10px;
    font-weight: ${fontWeight.medium};
    border-radius: ${defaultTheme.borderRadius.button};
`;

export const InstructionHeader = styled.p`
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
`;

export const FormContainer = styled.div`
    width: 100%;
`;
