import defaultTheme from '@shared/theme/default.theme';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    primaryButton: {
        margin: '20px 0',
        backgroundColor: defaultTheme.colors.primary,
        color: defaultTheme.colors.white,
        fontWeight: defaultTheme.fontWeight.bold,
        borderRadius: defaultTheme.borderRadius.button,
        '&:hover': {
            color: defaultTheme.colors.primary,
        },
    },
}));
