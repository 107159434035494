import { UserIdWithRole } from '@@types/models/User';
import createRequest from '@services/ApiService';

export default {
    updateUser: (user: UserIdWithRole) =>
        createRequest<UserIdWithRole>(
            `/admin/parish/users/${user._id}`,
            'PATCH',
            { role: user.role },
        ),
};
