export type AsyncStatusType =
    | 'IDLE'
    | 'PENDING'
    | 'ERROR'
    | 'SUCCESS'
    | 'CANCEL';

export const AsyncStatusRegExp = /(.*):(PENDING|SUCCESS|ERROR|CANCEL)/;

export interface AsyncStatus<E = { [key: string]: string }> {
    type: AsyncStatusType;
    notification?: boolean;
    message?: string;
    code?: number;
    errors?: E;
}
