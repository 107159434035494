import React from 'react';
import Container from '@material-ui/core/Container';
import ConfirmAccountForm from '../components/ConfirmAccountForm/ConfirmAccountForm';

const ConfirmAccountPage = () => {
    return (
        <Container component="main" maxWidth="xs">
            <ConfirmAccountForm />
        </Container>
    );
};

export default ConfirmAccountPage;
