import {
    IEvent,
    IUserFunctions,
    IUserSingleFunction,
} from '@@types/models/Events';

export interface IEventsModule {
    userFunctions: IUserFunctions[];
    events: IEvent[];
    newUserFunction: IUserSingleFunction[];
}

export type EventsModule = Partial<IEventsModule> | null;

export const initialState: EventsModule = null;
