import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import ListAltIcon from '@material-ui/icons/ListAlt';
import * as React from 'react';

export interface IDayNavigationProps {
    callback: (dayString: string) => void;
    className: string;
    path: string;
    primaryText: string;
}

const DayNavigation = ({
    callback,
    className,
    path,
    primaryText,
}: IDayNavigationProps) => {
    return (
        <List component="div" disablePadding>
            <ListItem
                button
                className={className}
                onClick={() => callback(path)}
            >
                <ListItemText primary={primaryText} />
            </ListItem>
        </List>
    );
};

export default DayNavigation;
