import React from 'react';
import Dashboard from '@domains/DashboardDomain/Dashboard';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import usePoints from './usePoints';
import DateFnsUtils from '@date-io/date-fns';
import plLocale from "date-fns/locale/pl";
import { GenerateButton, PickersContainer } from './Points.styled';

const Points = () => {
    const { startDate, setStartDate, endDate, setEndDate, getPointsCsv } = usePoints();
    return (
        <Dashboard>
            <p>
                Wygeneruj raport punktowy w pliku CSV, który później będziesz mógl otworzyć w arkuszu kalkulacyjnym.
            </p>
            <p>
                Wybierz przedział czasowy:
            </p>
            <PickersContainer>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                    <div>
                        <DatePicker
                            margin="normal"
                            name={'startDate'}
                            id={'startDate'}
                            label={'Od'}
                            value={startDate}
                            onChange={(date) => {
                                date && setStartDate(date);
                            }}
                            okLabel="OK"
                            cancelLabel="Anuluj"
                            format={'dd.MM.yyyy'}
                        />
                        <DatePicker
                            margin="normal"
                            name={'endDate'}
                            style={{ marginLeft: '10px' }}
                            id={'endDate'}
                            label={'Do'}
                            value={endDate}
                            onChange={(date) => {
                                date && setEndDate(date);
                            }}
                            okLabel="OK"
                            cancelLabel="Anuluj"
                            format={'dd.MM.yyyy'}
                        />
                    </div>
                </MuiPickersUtilsProvider>
                <GenerateButton onPress={getPointsCsv}>Wygeneruj</GenerateButton>
            </PickersContainer>
        </Dashboard>
    );
};

export default Points;
