import { createAsyncAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import {
    IConfirmAccountRequest,
    IResetPasswordRequest,
    ISigninRequest,
    ISignupRequest,
    IVerifyTokenRequest,
} from '@shared/api/authentication/requests';
import {
    IConfirmAccountResponse,
    ISigninResponse,
} from '@shared/api/authentication/responses';
import { IUser } from '@@types/models/User';

export const asyncSigninAction = createAsyncAction(
    '@authentication/SIGNIN:PENDING',
    '@authentication/SIGNIN:SUCCESS',
    '@authentication/SIGNIN:ERROR',
)<
    [ISigninRequest, ActionMeta],
    [ISigninResponse, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncSignupAction = createAsyncAction(
    '@authentication/SIGNUP:PENDING',
    '@authentication/SIGNUP:SUCCESS',
    '@authentication/SIGNUP:ERROR',
)<
    [ISignupRequest, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncVerifyTokenAction = createAsyncAction(
    '@authentication/VERIFY_TOKEN:PENDING',
    '@authentication/VERIFY_TOKEN:SUCCESS',
    '@authentication/VERIFY_TOKEN:ERROR',
)<
    [IVerifyTokenRequest, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncConfirmAccountAction = createAsyncAction(
    '@authentication/CONFIRM_ACCOUNT:PENDING',
    '@authentication/CONFIRM_ACCOUNT:SUCCESS',
    '@authentication/CONFIRM_ACCOUNT:ERROR',
)<
    [IConfirmAccountRequest, ActionMeta],
    [IConfirmAccountResponse, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncCheckLoggedUser = createAsyncAction(
    '@authentication/CHECK_LOGGED_USER:PENDING',
    '@authentication/CHECK_LOGGED_USER:SUCCESS',
    '@authentication/CHECK_LOGGED_USER:ERROR',
)<[undefined, ActionMeta], [IUser, ActionMeta], [undefined, ActionMeta]>();

export const asyncResetPasswordAction = createAsyncAction(
    '@authentication/RESET_PASSWORD:PENDING',
    '@authentication/RESET_PASSWORD:SUCCESS',
    '@authentication/RESET_PASSWORD:ERROR',
)<
    [IResetPasswordRequest, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();
