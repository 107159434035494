import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from 'src/state/reducers';
import sagas from '@state/sagas';

const configureStore = (): Store<any> => {
    const _WINDOW = window as any;
    const sagaMiddleware = createSagaMiddleware();
    const composeEnhancers =
        _WINDOW.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    let middleware = [sagaMiddleware];

    const store = createStore(
        reducers,
        composeEnhancers(applyMiddleware(...middleware)),
    ) as Store<any>;

    store.sagaTask = sagaMiddleware.run(sagas);

    return store;
};

const store = configureStore();

export default store;
