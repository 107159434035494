import {
    IEvent,
    IGetEventsRequest,
    ITemplateEventsRequestBody,
    IUserFunctions,
    IUserSingleFunction,
} from '@@types/models/Events';
import createRequest from '@services/ApiService';

export default {
    getUserFunctions: () =>
        createRequest<IUserFunctions[]>(`/admin/events/functions`, 'GET'),
    getEvents: ({ date }: IGetEventsRequest) =>
        createRequest<IEvent[]>(`/events`, 'POST', { date }),
    deleteTemplateEvents: ({ monthNumber, year }: ITemplateEventsRequestBody) =>
        createRequest<void>(`/admin/events/template`, 'DELETE', {
            monthNumber,
            year,
        }),
    addTemplateEvents: ({ monthNumber, year }: ITemplateEventsRequestBody) =>
        createRequest<void>(`/admin/events/template`, 'POST', {
            monthNumber,
            year,
        }),
    createUserFunction: ({ eventFunction }: IUserSingleFunction) =>
        createRequest<IUserSingleFunction[]>(
            `/admin/events/functions`,
            'POST',
            {
                eventFunction,
            },
        ),
    deleteUserFunction: ({ eventFunction }: IUserSingleFunction) =>
        createRequest<IUserSingleFunction[]>(
            `/admin/events/functions`,
            'DELETE',
            {
                eventFunction,
            },
        ),
};
