import { initialState, StatusModule } from './module';
import { AsyncStatusType, AsyncStatusRegExp } from '@@types/AsyncStatus';
import { PayloadMetaAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';

export default (
    state = initialState,
    action: PayloadMetaAction<string, unknown, ActionMeta | undefined>,
): StatusModule => {
    const matches = AsyncStatusRegExp.exec(action.type);

    if (!matches) {
        return state;
    }

    const [, prefix, status] = matches;

    return {
        ...state,
        [prefix]: {
            type: status as AsyncStatusType,
            message: action.meta?.message,
            code: action.meta?.code,
            errors: action.meta?.errors,
        },
    };
};
