import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUser } from '@@types/models/User';
import { useParams } from 'react-router-dom';
import alerts from '@@types/Alerts';
import { __DEV__ } from '@shared/constants';
import {
    IAlert,
    ITemplateEvent,
    ITemplateUser,
    WEEK_DAYS,
} from '@@types/models/Template';
import {
    asyncCreateEventTemplate,
    asyncDeleteEventTemplate,
    asyncGetEventTemplates,
    asyncUpdateEvents,
    asyncUpdateEventTemplate,
} from '@state/templates/actions';
import EnglishDays from '@@types/EnglishDays';
import { ITemplateUserDetails } from '@@types/models/Template';

export const getDefaultEventTemplate = (dayString: string): ITemplateEvent => ({
    name:
        dayString.toLowerCase() === 'niedziela'
            ? 'Msza niedzielna'
            : 'Msza codzienna',
    color: 'purple',
    users: [],
    startHour: '',
    endHour: '',
    points: {
        normal: 0,
        additional: 0,
    },
    alerts: [],
});

export default (values: ITemplateEvent) => {
    const { dayString }: { dayString: string } = useParams();
    const users = useSelector((state) => state.dashboard?.users) || [];
    const [eventValues, setEventValues] = useState(
        getDefaultEventTemplate(dayString),
    );
    const [shouldEditEvents, setShouldEditEvents] = useState(false);
    const [colorPicker, setColorPicker] = useState(false);

    useEffect(() => {
        setEventValues(values);
    }, [values]);

    const updateStartHour = (startHour: string) => {
        setEventValues({ ...eventValues, startHour });
    };

    const updateEventName = (name: string) => {
        setEventValues({ ...eventValues, name });
    };

    const handleColorPickerVisibility = () => {
        setColorPicker(!colorPicker);
    };

    const updateColor = (color: string) => {
        setEventValues({ ...eventValues, color });
    };

    const updateEndHour = (endHour: string) => {
        setEventValues({ ...eventValues, endHour });
    };

    const updateNormalPoints = (normal: number) => {
        setEventValues({
            ...eventValues,
            points: { ...eventValues.points, normal },
        });
    };

    const updateAdditionalPoints = (additional: number) => {
        setEventValues({
            ...eventValues,
            points: { ...eventValues.points, additional },
        });
    };

    const updateAlerts = (alerts: IAlert[]) => {
        setEventValues({
            ...eventValues,
            alerts,
        });
    };

    const updateUsers = (users: ITemplateUser[]) => {
        setEventValues({
            ...eventValues,
            users: users.map((el: any) => ({
                user: {
                    name: el.name,
                    surname: el.surname,
                    _id: el._id,
                },
                eventFunction:
                    eventValues.users.find(
                        (obj: any) => obj.user._id === el._id,
                    )?.eventFunction || [],
            })),
        });
    };

    const updateUserFunctions = (user: any, eventFunction: string[]) => {
        const tempArr = eventValues.users;
        const index = tempArr.findIndex(
            (el: ITemplateUser) =>
                (el.user as ITemplateUserDetails)._id === user.user._id,
        );
        tempArr[index].eventFunction = eventFunction;
    };

    const dispatch = useDispatch();
    const userFunctions =
        useSelector((state) =>
            state.events?.userFunctions?.map((func) => func.name),
        ) || [];

    const getUserOptionLabel = ({ name, surname }: IUser) =>
        `${name} ${surname}`;

    const [error, setError] = useState('');

    const onSubmit = () => {
        const startHourAsNumber = Number(
            eventValues.startHour.replace(':', ''),
        );

        const endHourAsNumber = Number(eventValues.endHour.replace(':', ''));

        if (!eventValues.startHour) {
            setError('Godzina rozpoczęcia nie może być pusta.');
            return;
        }

        if (!eventValues.endHour) {
            setError('Godzina zakończenia nie może być pusta.');
            return;
        }

        if (eventValues.startHour === eventValues.endHour) {
            setError(
                'Godzina rozpoczęcia nie może być taka sama jak godzina zakończenia.',
            );
            return;
        }

        if (startHourAsNumber > endHourAsNumber) {
            setError(
                'Godzina rozpoczęcia nie może być późniejsza niż godzina zakończenia.',
            );
            return;
        }

        const day = (EnglishDays as { [key: string]: string })[
            dayString.toLowerCase()
        ];
        if (!eventValues._id) {
            dispatch(
                asyncCreateEventTemplate.request(
                    { eventTemplate: eventValues, day: day as WEEK_DAYS },
                    {
                        notification: __DEV__,
                        callbacks: {
                            onSuccess: () => {
                                dispatch(
                                    asyncGetEventTemplates.request(day, {
                                        notification: __DEV__,
                                    }),
                                );
                            },
                        },
                    },
                ),
            );
        } else {
            dispatch(
                asyncUpdateEventTemplate.request(
                    {
                        eventTemplate: eventValues,
                        templateId: eventValues._id,
                    },
                    {
                        notification: __DEV__,
                        callbacks: {
                            onSuccess: () => {
                                dispatch(
                                    asyncGetEventTemplates.request(day, {
                                        notification: __DEV__,
                                    }),
                                );
                                if (shouldEditEvents) {
                                    dispatch(
                                        asyncUpdateEvents.request(
                                            {
                                                templateId: eventValues._id as string,
                                            },
                                            { notification: __DEV__ },
                                        ),
                                    );
                                }
                            },
                        },
                    },
                ),
            );
        }
    };

    const onDelete = () => {
        const day = (EnglishDays as { [key: string]: string })[
            dayString.toLowerCase()
        ];
        dispatch(
            asyncDeleteEventTemplate.request(
                { templateId: eventValues._id as string },
                {
                    notification: __DEV__,
                    callbacks: {
                        onSuccess: () => {
                            dispatch(
                                asyncGetEventTemplates.request(day, {
                                    notification: __DEV__,
                                }),
                            );
                        },
                    },
                },
            ),
        );
    };

    const [openPropmt, setOpenPropmt] = useState(false);

    return {
        users,
        userFunctions,
        alerts,
        getUserOptionLabel,
        onSubmit,
        eventValues,
        updateStartHour,
        updateEndHour,
        updateNormalPoints,
        updateAdditionalPoints,
        updateAlerts,
        updateUsers,
        updateUserFunctions,
        updateEventName,
        updateColor,
        onDelete,
        shouldEditEvents,
        setShouldEditEvents,
        openPropmt,
        setOpenPropmt,
        error,
        setError,
        colorPicker,
        handleColorPickerVisibility,
    };
};
