import styled from 'styled-components';

export const InstructionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

export const Instruction = styled.div``;

export const PriceText = styled.div`
    font-size: 18px;
`;

export const PayuHref = styled.a``;
