import * as React from 'react';

function LogoWide(props: any) {
    return (
        <svg
            id="Logo"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 96 37"
            xmlSpace="preserve"
            {...props}
        >
            <style>{'.prefix__st0{fill:#4f44ff}'}</style>
            <path
                className="prefix__st0"
                d="M14.84 12.87H11.33c-.02 0-.04.01-.05.03 0 0-1.26 14.06-10.29 17.82-.04.02.01.09.05.08 13.93-1.67 14.12-12.07 14.04-17.92h-.24zM15.18 6.24l-3.87 4.61c-.02.04.01.08.05.08h3.92V6.26c0-.05-.07-.07-.1-.02zM31.4 30.71c-9.02-3.76-10.29-17.82-10.29-17.82a.055.055 0 00-.05-.03H17.3c-.08 5.86.11 16.25 14.04 17.92.05.02.1-.05.06-.07zM21.09 10.85l-3.87-4.61c-.03-.05-.1-.03-.1.03v4.67h3.92c.04-.01.07-.06.05-.09z"
            />
            <g>
                <path d="M38.77 20.16h-5.22l-1.08 2.45h-1.44l4.45-9.82h1.39l4.46 9.82h-1.47l-1.09-2.45zm-.49-1.12l-2.12-4.81-2.12 4.81h4.24zM50.28 18.9c0 2.28-1.58 3.8-3.76 3.8-1.05 0-1.98-.41-2.59-1.21v3.84h-1.35V15.18h1.29v1.18c.6-.83 1.56-1.25 2.65-1.25 2.18 0 3.76 1.52 3.76 3.79zm-1.36 0c0-1.57-1.08-2.61-2.51-2.61-1.42 0-2.5 1.04-2.5 2.61 0 1.58 1.08 2.62 2.5 2.62 1.43 0 2.51-1.04 2.51-2.62zM59.84 18.9c0 2.28-1.58 3.8-3.76 3.8-1.05 0-1.98-.41-2.59-1.21v3.84h-1.35V15.18h1.29v1.18c.6-.83 1.56-1.25 2.65-1.25 2.17 0 3.76 1.52 3.76 3.79zm-1.36 0c0-1.57-1.08-2.61-2.51-2.61-1.42 0-2.5 1.04-2.5 2.61 0 1.58 1.08 2.62 2.5 2.62 1.43 0 2.51-1.04 2.51-2.62zM61.01 18.9c0-2.22 1.64-3.79 3.87-3.79s3.86 1.57 3.86 3.79c0 2.22-1.63 3.8-3.86 3.8s-3.87-1.59-3.87-3.8zm6.37 0c0-1.58-1.07-2.61-2.5-2.61s-2.51 1.02-2.51 2.61c0 1.58 1.08 2.62 2.51 2.62 1.43 0 2.5-1.04 2.5-2.62zM69.66 21.83l.56-1.07c.63.45 1.64.77 2.61.77 1.25 0 1.77-.38 1.77-1.01 0-1.67-4.7-.22-4.7-3.18 0-1.33 1.19-2.23 3.1-2.23.97 0 2.06.25 2.71.67l-.58 1.07c-.67-.43-1.42-.59-2.15-.59-1.18 0-1.75.43-1.75 1.02 0 1.75 4.71.32 4.71 3.21 0 1.35-1.23 2.2-3.21 2.2-1.23.01-2.45-.37-3.07-.86zM81.8 22.18c-.41.35-1.02.52-1.63.52-1.5 0-2.36-.83-2.36-2.33v-4.08h-1.26v-1.11h1.26v-1.63h1.35v1.63h2.13v1.11h-2.13v4.02c0 .8.42 1.25 1.16 1.25.39 0 .77-.13 1.05-.35l.43.97zM82.49 18.9c0-2.22 1.64-3.79 3.87-3.79s3.86 1.57 3.86 3.79c0 2.22-1.63 3.8-3.86 3.8s-3.87-1.59-3.87-3.8zm6.36 0c0-1.58-1.07-2.61-2.5-2.61s-2.51 1.02-2.51 2.61c0 1.58 1.08 2.62 2.51 2.62 1.44 0 2.5-1.04 2.5-2.62zM93.53 17.71v4.91h-1.35v-3.88l-.88.66-.6-.8 1.49-1.14V12.2h1.35v4.23l.88-.67.6.81-1.49 1.14z" />
            </g>
        </svg>
    );
}

export default LogoWide;
