import { createAsyncAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import {
    IEvent,
    IGetEventsRequest,
    ITemplateEventsRequestBody,
    IUserFunctions,
    IUserSingleFunction,
} from '@@types/models/Events';

export const asyncGetUserFunctions = createAsyncAction(
    '@events/GET_USER_FUNCTION:PENDING',
    '@events/GET_USER_FUNCTION:SUCCESS',
    '@events/GET_USER_FUNCTION:ERROR',
)<
    [undefined, ActionMeta],
    [IUserFunctions[], ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncGetEvents = createAsyncAction(
    '@events/GET_EVENTS:PENDING',
    '@events/GET_EVENTS:SUCCESS',
    '@events/GET_EVENTS:ERROR',
)<
    [IGetEventsRequest, ActionMeta],
    [IEvent[], ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncRemoveTemplateEvents = createAsyncAction(
    '@events/REMOVE_TEMPLATE_EVENTS:PENDING',
    '@events/REMOVE_TEMPLATE_EVENTS:SUCCESS',
    '@events/REMOVE_TEMPLATE_EVENTS:ERROR',
)<
    [ITemplateEventsRequestBody, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncAddTemplateEvents = createAsyncAction(
    '@events/ADD_TEMPLATE_EVENTS:PENDING',
    '@events/ADD_TEMPLATE_EVENTS:SUCCESS',
    '@events/ADD_TEMPLATE_EVENTS:ERROR',
)<
    [ITemplateEventsRequestBody, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncCreateUserFunction = createAsyncAction(
    '@events/CREATE_USER_FUNCTION:PENDING',
    '@events/CREATE_USER_FUNCTION:SUCCESS',
    '@events/CREATE_USER_FUNCTION:ERROR',
)<
    [IUserSingleFunction, ActionMeta],
    [IUserSingleFunction, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncDeleteUserFunction = createAsyncAction(
    '@events/DELETE_USER_FUNCTION:PENDING',
    '@events/DELETE_USER_FUNCTION:SUCCESS',
    '@events/DELETE_USER_FUNCTION:ERROR',
)<
    [IUserSingleFunction, ActionMeta],
    [IUserSingleFunction, ActionMeta],
    [undefined, ActionMeta]
>();
