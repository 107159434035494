import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import {
    asyncGetEvents,
    asyncGetUserFunctions,
    asyncCreateUserFunction,
    asyncDeleteUserFunction,
} from './actions';

export default createReducer(initialState)
    .handleAction([asyncGetUserFunctions.success], (state, action) => ({
        ...state,
        userFunctions: action.payload,
    }))
    .handleAction([asyncGetEvents.success], (state, action) => ({
        ...state,
        events: action.payload,
    }))
    .handleAction([asyncCreateUserFunction.success], (state, action) => ({
        ...state,
        newUserFunctions: action.payload,
    }))
    .handleAction([asyncDeleteUserFunction.success], (state, action) => ({
        ...state,
        newUserFunctions: action.payload,
    }));
