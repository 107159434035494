import React from 'react';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {
    CssBaseline,
    AppBar,
    Container,
    Divider,
    Drawer,
    IconButton,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { useStyles } from './Dashboard.styles';
import useDashboard from './useDashboard';
import usePayuScript from '@utils/hooks/usePayuScript';
import LeftMenu from '@components/LeftMenu/LeftMenu';
import LogoWide from '@shared/svgs/logo_wide';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={2} variant="filled" {...props} />;
}

const Dashboard = ({ children }: any) => {
    const classes = useStyles();
    usePayuScript();
    const { logout, header, shouldShowAlert, daysToDueDate } = useDashboard();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(
                            classes.menuButton,
                            open && classes.menuButtonHidden,
                        )}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        {header}
                    </Typography>
                    <IconButton color="inherit" onClick={logout}>
                        <ExitToApp />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(
                        classes.drawerPaper,
                        !open && classes.drawerPaperClose,
                    ),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <LogoWide className={classes.logo} />
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <LeftMenu />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    {children}
                </Container>
                {shouldShowAlert && (
                    <Alert
                        severity={daysToDueDate < 0 ? 'error' : 'warning'}
                        className={classes.alert}
                    >
                        {daysToDueDate < 0
                            ? `Twoja subskrypcja wygasła ${Math.abs(
                                  daysToDueDate,
                              )} dni temu!`
                            : `Twoja subskrypcja skończy się za ${daysToDueDate} dni!`}
                    </Alert>
                )}
            </main>
        </div>
    );
};

export default Dashboard;
