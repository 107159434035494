import React from 'react';
import Dashboard from '@domains/DashboardDomain/Dashboard';
import useSettings from './useSettings';
import Checkbox from '@material-ui/core/Checkbox';
import defaultTheme from '@shared/theme/default.theme';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { Description, SubmitButton } from './Settings.styled';
import { Alert } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

const Settings = () => {
    const {
        approvePoints,
        setApprovePoints,
        alert,
        onSubmit,
        pointsHoursDeadline,
        setPointsHoursDeadline,
        subtractPoints,
        setSubtractPoints,
    } = useSettings();
    return (
        <Dashboard>
            <h1>Ustawienia punktacji</h1>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={approvePoints}
                            style={{ color: defaultTheme.colors.primary }}
                            onChange={(event) =>
                                setApprovePoints(event.target.checked)
                            }
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label="Akceptacja punktów"
                />
            </FormGroup>
            <Description>
                Zaznaczając tę opcję dodatkowe punkty przydzielane sobie przez
                użytkowników wymagają zaakceptowania przez Administratora lub
                SuperUsera.
            </Description>
            <TextField
                id="standard-number"
                label="Godziny"
                type="number"
                style={{ width: '50px', marginBottom: '10px' }}
                value={pointsHoursDeadline}
                onChange={(event) =>
                    setPointsHoursDeadline(
                        +event.target.value > 0 ? +event.target.value : 1,
                    )
                }
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Description>
                Wybierz czas po którym użytkownicy mogą nadal odebrać punkty za
                wydarzenie.
            </Description>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={subtractPoints}
                            style={{ color: defaultTheme.colors.primary }}
                            onChange={(event) =>
                                setSubtractPoints(event.target.checked)
                            }
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label="Automatyczne odejmowanie punktów"
                />
            </FormGroup>
            <Description>
                Punkty będą odejmowane automatycznie użytkownikom, którzy nie
                odebrali ich za obowiązkowe wydarzenie.
            </Description>
            <SubmitButton onPress={() => onSubmit()}>Zapisz</SubmitButton>
            {alert && (
                <Alert severity="success">
                    Dane zostały poprawne zapisane.
                </Alert>
            )}
        </Dashboard>
    );
};

export default Settings;
