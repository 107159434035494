import { palette } from '@shared/theme/config';
import styled from 'styled-components';

export const SecureFormContainer = styled.div`
    text-align: center;
    width: 420px;
    margin: 20px auto 10px;
    display: block;
    border-radius: 5px;
    color: ${palette.black};
    box-sizing: border-box;
`;

export const CardContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    border-radius: 6px;
    padding: 10px;
    text-align: left;
    box-sizing: border-box;
`;

export const Aside = styled.div`
    padding-bottom: 6px;
`;

export const PayuCardForm = styled.div`
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px;
`;

export const CardDetails = styled.div`
    clear: both;
    overflow: auto;
    margin-top: 10px;
`;

export const Expiration = styled.div`
    width: 50%;
    float: left;
    padding-right: 5%;
`;

export const CVV = styled.div`
    width: 50%;
    float: left;
`;
export const Error = styled.p`
    color ${palette.red};
`;
