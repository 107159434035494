import {
    ICreateEventTemplateBody,
    IDeleteEventTemplateBody,
    ITemplate,
    ITemplateEvent,
    IUpdateEventsBody,
    IUpdateEventTemplateBody,
} from '@@types/models/Template';
import createRequest from '@services/ApiService';

export default {
    getTemplate: () =>
        createRequest<ITemplate>(`/admin/parish/template`, 'GET'),
    postTemplate: ({ day, eventTemplate }: ICreateEventTemplateBody) =>
        createRequest<any>(`/admin/templates`, 'POST', {
            day,
            template: eventTemplate,
        }),
    getTemplates: (day: string) =>
        createRequest<ITemplateEvent[]>(`/admin/templates/day/${day}`, 'GET'),
    updateTemplate: ({ eventTemplate, templateId }: IUpdateEventTemplateBody) =>
        createRequest(`/admin/templates/${templateId}`, 'PATCH', {
            ...eventTemplate,
        }),
    deleteTemplate: ({ templateId }: IDeleteEventTemplateBody) =>
        createRequest(`/admin/templates/${templateId}`, 'DELETE'),
    updateEvents: ({ templateId }: IUpdateEventsBody) =>
        createRequest(`/admin/events/template/${templateId}`, 'PATCH'),
};
