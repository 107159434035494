import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
    name: string;
    label: string;
    onChange: (value: string) => void;
    value?: string;
    defaultValue?: string;
}

const useStyle = makeStyles(() => ({
    nameInput: {
        minWidth: '200px',
    },
}));

const TextInput = ({ name, label, onChange, value, defaultValue }: IProps) => {
    const classes = useStyle();

    useEffect(() => {
        if (!value && defaultValue) {
            onChange(defaultValue);
        }
    }, [value, defaultValue]);

    return (
        <>
            <TextField
                className={classes.nameInput}
                id={name}
                label={label}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                value={value ? value : defaultValue}
            />
        </>
    );
};

export default TextInput;
