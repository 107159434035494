import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { asyncGetUserFunctions } from '@state/events/actions';
import useActionLoading from '@utils/hooks/useActionLoading';
import useActionStatus from '@utils/hooks/useActionStatus';
import {
    asyncCreateUserFunction,
    asyncDeleteUserFunction,
} from '@state/events/actions';
import { IUserSingleFunction } from '@@types/models/Events';
import { __DEV__ } from '@shared/constants';

export default () => {
    const dispatch = useDispatch();
    const {
        register,
        control,
        handleSubmit,
        clearErrors,
        errors,
        reset,
        formState: { isValid, isDirty },
    } = useForm({
        mode: 'onChange',
    });

    const userFunctions = useSelector((state) => state.events?.userFunctions);

    const getUserFunctions = () =>
        dispatch(
            asyncGetUserFunctions.request(undefined, {
                notification: __DEV__,
            }),
        );

    useEffect(() => {
        if (!userFunctions) {
            getUserFunctions();
        }
    }, []);

    const isSubmitting = useActionLoading(asyncCreateUserFunction);
    const actionStatus = useActionStatus(asyncCreateUserFunction);

    const onSubmit = handleSubmit((payload: IUserSingleFunction) => {
        reset();
        dispatch(
            asyncCreateUserFunction.request(payload, {
                notification: __DEV__,
            }),
        );
    });

    const handleDelete = (payload: IUserSingleFunction) => {
        dispatch(
            asyncDeleteUserFunction.request(payload, {
                notification: __DEV__,
            }),
        );
    };

    return {
        handleDelete,
        userFunctions,
        register,
        control,
        onSubmit,
        errors,
        isValid,
        isSubmitting,
        isDirty,
        clearErrors,
        addFunctionError: actionStatus?.code === 401 ? true : false,
    };
};
