import { __DEV__ } from '@shared/constants';
import { asyncGetPointsCsv } from '@state/points/actions';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';

export default () => {
    const [startDate, setStartDate] = useState(startOfYear(new Date()));
    const [endDate, setEndDate] = useState(endOfYear(new Date()));
    const dispatch = useDispatch();

    const getPointsCsv = () => {
        const payload = `startDate=${startDate.valueOf()}&endDate=${endDate.valueOf()}`;
        dispatch(
            asyncGetPointsCsv.request(payload, {
                notification: __DEV__,
            }),
        );
    };

    return {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        getPointsCsv,
    };
};
