import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IAlert } from '@@types/models/Template';
import { useState } from 'react';

interface IProps {
    alerts: IAlert[];
    noOptionText: string;
    placeholder: string;
    helperText: string;
    name: string;
    defaultValue: IAlert[];
    onChange: (alerts: IAlert[]) => void;
}

const Notifications = ({
    alerts,
    noOptionText,
    placeholder,
    helperText,
    name,
    onChange,
    defaultValue,
}: IProps) => {
    const [notifications, setNotifications] = useState<IAlert[]>([]);
    useEffect(() => {
        setNotifications(defaultValue as any);
    }, [defaultValue]);
    return (
        <Autocomplete
            multiple
            options={alerts.map((alerts) => alerts)}
            noOptionsText={noOptionText}
            onChange={(_, value) => {
                setNotifications(value);
                onChange(value as any);
            }}
            value={notifications}
            getOptionSelected={(Option, value) => Option.label === value.label}
            getOptionLabel={(alert) => alert.label}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label=""
                    placeholder={placeholder}
                    helperText={helperText}
                />
            )}
        />
    );
};

export default Notifications;
