import { IUser } from '@@types/models/User';
import createRequest from '@services/ApiService';

export default {
    getParishUsers: (showParents = false) =>
        createRequest<IUser[]>(
            `/admin/parish/users?showParents=${showParents}`,
            'GET',
        ),
};
