import { createAsyncAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import { ProfileData } from '@@types/models/ProfileData';

export const asyncAddProfile = createAsyncAction(
    '@profile/ADD_PROFILE:PENDING',
    '@profile/ADD_PROFILE:SUCCESS',
    '@profile/ADD_PROFILE:ERROR',
)<
    [ProfileData, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncGetProfile = createAsyncAction(
    '@profile/GET_PROFILE:PENDING',
    '@profile/GET_PROFILE:SUCCESS',
    '@profile/GET_PROFILE:ERROR',
)<
    [undefined, ActionMeta],
    [ProfileData, ActionMeta],
    [undefined, ActionMeta]
>();
