export enum WEEK_DAYS {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export interface ITemplateUserDetails {
    _id: string;
    name: string;
    surname: string;
}

export interface ITemplateUser {
    user: ITemplateUserDetails | string;
    eventFunction: string[];
}

export interface IAlert {
    type: string;
    label: string;
}

export interface ITemplateEvent {
    name: string;
    color: string;
    _id?: string;
    users: ITemplateUser[];
    startHour: string;
    endHour: string;
    points: {
        normal: number;
        additional: number;
    };
    alerts: IAlert[];
}
export interface ITemplate {
    monday: ITemplateEvent[];
    tuesday: ITemplateEvent[];
    wednesday: ITemplateEvent[];
    thursday: ITemplateEvent[];
    friday: ITemplateEvent[];
    saturday: ITemplateEvent[];
    sunday: ITemplateEvent[];
}

export interface IDefaultValues {
    eventTemplate: {
        users: {
            name: string;
            eventFunction: string[];
        }[];
        startHour: string;
        endHour: string;
        points: {
            normal: number;
            additional: number;
        };
        alerts: IAlert[];
    }[];
}

export interface ICreateEventTemplateBody {
    day: WEEK_DAYS;
    eventTemplate: ITemplateEvent;
}

export interface IUpdateEventTemplateBody {
    templateId: string;
    eventTemplate: ITemplateEvent;
}

export interface IDeleteEventTemplateBody {
    templateId: string;
}

export interface IUpdateEventsBody {
    templateId: string;
}
