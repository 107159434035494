import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { IPayment, PAYMENT_STATUS } from '@@types/models/Payments';
import moment from 'moment';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        marginTop: '40px',
    },
});

const PaymentsTable = ({
    payments,
    sendEmail,
}: {
    payments: IPayment[];
    sendEmail: (paymentId: string) => void;
}) => {
    const classes = useStyles();
    return (
        <TableContainer component={Paper} className={classes.container}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Płatność za</TableCell>
                        <TableCell align="center">Kwota</TableCell>
                        <TableCell align="center">Data płatności</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Wyślij</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payments.map((payment, index) => (
                        <TableRow key={payment._id}>
                            <TableCell component="th" align="center">
                                {index + 1}
                            </TableCell>
                            <TableCell component="th" align="center">
                                {payment.additionalDescription}
                            </TableCell>
                            <TableCell component="th" align="center">
                                {payment.totalAmount / 100}
                            </TableCell>
                            <TableCell component="th" align="center">
                                {moment(payment.orderCreateDate).format(
                                    'DD.MM.YYYY HH:mm',
                                )}
                            </TableCell>
                            <TableCell component="th" align="center">
                                {payment.status === PAYMENT_STATUS.COMPLETED
                                    ? 'Zaakceptowano'
                                    : 'Odrzucono'}
                            </TableCell>
                            <TableCell
                                component="th"
                                align="center"
                                style={{ cursor: 'pointer' }}
                                onClick={() => sendEmail(payment._id)}
                            >
                                Mail
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PaymentsTable;
