import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ISigninRequest } from '@shared/api/authentication/requests';
import useActionLoading from '@utils/hooks/useActionLoading';
import useActionStatus from '@utils/hooks/useActionStatus';
import {
    asyncCheckLoggedUser,
    asyncSigninAction,
} from '@state/authentication/actions';
import { useHistory } from '@utils/hooks';
import paths from '@shared/paths';
import { useEffect } from 'react';
import { __DEV__ } from '@shared/constants';

export default () => {
    const dispatch = useDispatch();
    const {
        register,
        control,
        handleSubmit,
        clearErrors,
        errors,
        formState: { isValid, isDirty },
    } = useForm({
        mode: 'onChange',
    });

    const checkLoggedUser = () =>
        dispatch(
            asyncCheckLoggedUser.request(undefined, {
                notification: __DEV__,
                callbacks: {
                    onSuccess: () => {
                        navigate(paths.users);
                    },
                },
            }),
        );

    useEffect(() => {
        checkLoggedUser();
    }, []);

    const isSubmitting = useActionLoading(asyncSigninAction);
    const actionStatus = useActionStatus(asyncSigninAction);
    const { navigate } = useHistory();
    const onSubmit = handleSubmit((payload: ISigninRequest) => {
        dispatch(
            asyncSigninAction.request(payload, {
                notification: __DEV__,
                callbacks: {
                    onSuccess: () => {
                        navigate(paths.users);
                    },
                },
            }),
        );
    });

    return {
        register,
        control,
        onSubmit,
        errors,
        isValid,
        isSubmitting,
        isDirty,
        clearErrors,
        signInError: actionStatus?.code === 401 ? true : false,
    };
};
