export interface IUser {
    _id: string;
    name: string;
    surname: string;
    role: ROLES;
    parish: string;
    email: string;
    phone: string;
}

export enum ROLES {
    USER = 'user',
    SUPER_USER = 'super_user',
    ADMIN = 'admin',
    PARENT = 'parent',
}

export interface IUpdateUserBody {
    _id: string;
    role: ROLES;
}

export type UserIdWithRole = Pick<IUser, '_id' | 'role'>;
