import { useHistory as useRouterHistory } from 'react-router-dom';

let previous = 0;

export default () => {
    const history = useRouterHistory<{ length: string }>();

    history.listen((_, action) => {
        if (action === 'PUSH') {
            previous += 1;
        }
    });

    const navigate = (routeName: string, replace?: boolean) => {
        replace ? history.replace(routeName) : history.push(routeName);
    };

    const goBack = () => {
        if (previous > 0) {
            previous -= 1;
            history.goBack();
        } else {
            history.push('/');
        }
    };

    const getQueryParam = (query: string) =>
        new URLSearchParams(history.location.search).get(query);

    return { history, navigate, goBack, getQueryParam };
};
