import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { __DEV__ } from '@shared/constants';
import { AxiosResponse } from 'axios';
import { pointsApi } from '@shared/api';
import { PayloadMetaAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import fileDownload from 'js-file-download';

export function* getPointsCsv(
    action: PayloadMetaAction<string, string, ActionMeta>,
) {
    try {
        const response: AxiosResponse<string> = yield call(
            pointsApi.getCsv,
            action.payload,
        );
        fileDownload(response.data, 'punktacja.csv');
        yield put(
            actions.asyncGetPointsCsv.success(response.data, {
                message: 'Got Points csv',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncGetPointsCsv.failure(undefined, {
                message: (message as string) || (error as string),
                code: status as number,
                notification: __DEV__,
                errors: errors as { [key: string]: string },
            }),
        );
    }
}

function* watchGetPointsCsv() {
    yield takeLatest(actions.asyncGetPointsCsv.request, getPointsCsv);
}

export default function* () {
    yield all([watchGetPointsCsv()]);
}
