import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { __DEV__ } from '@shared/constants';
import { AxiosResponse } from 'axios';
import { eventsApi } from '@shared/api';
import {
    IEvent,
    IGetEventsRequest,
    ITemplateEventsRequestBody,
    IUserFunctions,
    IUserSingleFunction,
} from '@@types/models/Events';
import { PayloadMetaAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';

export function* getUserFunctions() {
    try {
        const response: AxiosResponse<IUserFunctions[]> = yield call(
            eventsApi.getUserFunctions,
        );
        yield put(
            actions.asyncGetUserFunctions.success([...response.data], {
                message: 'Got user functions',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncGetUserFunctions.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* getEvents(
    action: PayloadMetaAction<string, IGetEventsRequest, ActionMeta>,
) {
    try {
        const response: AxiosResponse<
            IEvent[]
        > = yield call(eventsApi.getEvents, { date: action.payload.date });
        yield put(
            actions.asyncGetEvents.success([...response.data], {
                message: 'Got events',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncGetEvents.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}
export function* removeTemplateEvents(
    action: PayloadMetaAction<string, ITemplateEventsRequestBody, ActionMeta>,
) {
    try {
        yield call(eventsApi.deleteTemplateEvents, {
            monthNumber: action.payload.monthNumber,
            year: action.payload.year,
        });
        yield put(
            actions.asyncRemoveTemplateEvents.success(undefined, {
                message: 'removed events',
                notification: __DEV__,
            }),
        );
        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncRemoveTemplateEvents.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}
export function* addTemplateEvents(
    action: PayloadMetaAction<string, ITemplateEventsRequestBody, ActionMeta>,
) {
    try {
        yield call(eventsApi.addTemplateEvents, {
            monthNumber: action.payload.monthNumber,
            year: action.payload.year,
        });
        yield put(
            actions.asyncAddTemplateEvents.success(undefined, {
                message: 'added template events',
                notification: __DEV__,
            }),
        );
        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncAddTemplateEvents.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* createUserFunction(
    action: PayloadMetaAction<string, IUserSingleFunction, ActionMeta>,
) {
    try {
        const response: AxiosResponse<IUserSingleFunction> = yield call(
            eventsApi.createUserFunction,
            { eventFunction: action.payload.eventFunction },
        );
        yield put(
            actions.asyncCreateUserFunction.success(response.data, {
                message: 'created user function',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncCreateUserFunction.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* deleteUserFunction(
    action: PayloadMetaAction<string, IUserSingleFunction, ActionMeta>,
) {
    try {
        const response: AxiosResponse<IUserSingleFunction> = yield call(
            eventsApi.deleteUserFunction,
            { eventFunction: action.payload.eventFunction },
        );
        yield put(
            actions.asyncDeleteUserFunction.success(response.data, {
                message: 'deleted user function',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncDeleteUserFunction.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

function* watchGetUserFunctions() {
    yield takeLatest(actions.asyncGetUserFunctions.request, getUserFunctions);
}

function* watchGetEvents() {
    yield takeLatest(actions.asyncGetEvents.request, getEvents);
}

function* watchRemoveTemplateEvents() {
    yield takeLatest(
        actions.asyncRemoveTemplateEvents.request,
        removeTemplateEvents,
    );
}

function* watchAddTemplateEvents() {
    yield takeLatest(actions.asyncAddTemplateEvents.request, addTemplateEvents);
}

function* watchCreateUserFunction() {
    yield takeLatest(
        actions.asyncCreateUserFunction.request,
        createUserFunction,
    );
    yield takeLatest(actions.asyncCreateUserFunction.success, getUserFunctions);
}

function* watchDeleteUserFunction() {
    yield takeLatest(
        actions.asyncDeleteUserFunction.request,
        deleteUserFunction,
    );
    yield takeLatest(actions.asyncDeleteUserFunction.success, getUserFunctions);
}

export default function* () {
    yield all([
        watchGetUserFunctions(),
        watchGetEvents(),
        watchRemoveTemplateEvents(),
        watchAddTemplateEvents(),
        watchCreateUserFunction(),
        watchDeleteUserFunction(),
    ]);
}
