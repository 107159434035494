import { PAYU_URL } from '@shared/constants';
import { useEffect } from 'react';

export default () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = PAYU_URL;
        script.async = true;

        document.body.appendChild(script);
    }, []);

    return {};
};
