import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ITemplateUser } from '@@types/models/Template';
import { IUser } from '@@types/models/User';

interface IProps {
    data: IUser[];
    noOptionText: string;
    placeholder: string;
    helperText: string;
    callback?: (data: any) => void;
    getOptionLabel?: (option: any) => string;
    defaultValue?: any[];
    onChange: (users: ITemplateUser[]) => void;
}

const MultipleChoice = ({
    data,
    noOptionText,
    placeholder,
    helperText,
    onChange,
    defaultValue,
}: IProps) => {
    const [users, setUsers] = useState<IUser[]>([]);
    useEffect(() => {
        setUsers(defaultValue?.map(({ user }) => user) as any);
    }, [defaultValue]);

    return (
        <Autocomplete
            multiple
            onChange={(_, value) => {
                setUsers(value as any);
                onChange(value as any);
            }}
            options={data.filter((user: IUser) =>
                users.every(({ _id }) => _id !== user._id),
            )}
            noOptionsText={noOptionText}
            getOptionLabel={(user) => `${user.name} ${user.surname}`}
            value={users}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label=""
                    placeholder={placeholder}
                    helperText={helperText}
                />
            )}
        />
    );
};

export default MultipleChoice;
