export interface ISubscriptionParish {
    name: string;
    dueDate: Date;
}

export interface IPayment {
    _id: string;
    description: string;
    currencyCode: string;
    totalAmount: number;
    orderCreateDate: Date;
    additionalDescription: string;
    status: PAYMENT_STATUS;
}

export enum PAYMENT_STATUS {
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED',
}
export interface ISubscription {
    cards: ICard[];
    payments: IPayment[];
    parish: ISubscriptionParish;
}

export interface IGetPaymentLink {
    redirectUri: string;
}

export interface IAddCardRequest {
    token: string;
}

export enum CARD_STATUS {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
}

export interface ICard {
    brandImageUrl: String;
    cardBrand: String;
    cardExpirationMonth: Number;
    cardExpirationYear: Number;
    cardNumberMasked: String;
    status: CARD_STATUS;
    value: string;
}

export interface IDeleteCardRequest {
    token: string;
}
