import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { IResetPasswordRequest } from '@shared/api/authentication/requests';
import useActionLoading from '@utils/hooks/useActionLoading';
import useActionStatus from '@utils/hooks/useActionStatus';
import { asyncResetPasswordAction } from '@state/authentication/actions';
import { useHistory } from '@utils/hooks';
import paths from '@shared/paths';
import { __DEV__ } from '@shared/constants';
import { useState } from 'react';

export interface IForm {
    password: string;
    confirmPassword: string;
}

export default () => {
    const dispatch = useDispatch();
    const { getQueryParam } = useHistory();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const {
        register,
        control,
        handleSubmit,
        clearErrors,
        errors,
        formState: { isValid, isDirty },
    } = useForm({
        mode: 'onChange',
    });

    const isSubmitting = useActionLoading(asyncResetPasswordAction);
    const actionStatus = useActionStatus(asyncResetPasswordAction);
    const { navigate } = useHistory();
    const onSubmit = handleSubmit((payload: IForm) => {
        if (payload.password === payload.confirmPassword) {
            const resetToken = getQueryParam('resetToken') || '';
            dispatch(
                asyncResetPasswordAction.request(
                    { ...payload, resetToken },
                    {
                        notification: __DEV__,
                        callbacks: {
                            onSuccess: () => {
                                setError('');
                                navigate(paths.login);
                            },
                            onError: () => {
                                setError(
                                    'Coś poszło nie tak. Pamiętaj, że link z poczty jest jednorazowy.',
                                );
                            },
                        },
                    },
                ),
            );
        } else {
            setError('Hasła muszą być takie same.');
        }
    });

    return {
        register,
        control,
        onSubmit,
        errors,
        isValid,
        isSubmitting,
        isDirty,
        clearErrors,
        error,
        setPassword,
        setConfirmPassword,
    };
};
