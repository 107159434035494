import defaultTheme from '@shared/theme/default.theme';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: defaultTheme.fontSizes.xl,
        fontWeight: defaultTheme.fontWeight.bold,
    },

    button: {
        margin: '20px 0',
        backgroundColor: defaultTheme.colors.secondary,
        color: defaultTheme.colors.white,
        fontWeight: defaultTheme.fontWeight.bold,
        borderRadius: defaultTheme.borderRadius.button,
        '&:hover': {
            color: defaultTheme.colors.secondary,
        },
    },
}));

export const AddEventTemplate = styled.div`
    display: flex;
    margin-top: 20px;
    justify-content: center;
`;

export const LoaderContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 25px;
`;
