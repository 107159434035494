import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import paths from '@shared/paths';
import { useHistory } from '@utils/hooks';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { asyncGetSubscription } from '@state/payments/actions';
import { useEffect } from 'react';
import { __DEV__ } from '@shared/constants';

export default () => {
    const { navigate } = useHistory();
    const dispatch = useDispatch();

    const logout = () => {
        LocalStorageManager.setValue('token', '');
        navigate(paths.login);
    };

    const getSubscription = () =>
        dispatch(
            asyncGetSubscription.request(undefined, {
                notification: __DEV__,
            }),
        );

    const getHeader = () => {
        switch (window.location.pathname) {
            case paths.users:
                return 'Aktywni użytkownicy';
            case paths.payments:
                return 'Płatności';
            case paths.dayTemplate:
                return 'Szablony wydarzenia';
            case paths.calendar:
                return 'Kalendarz';
            case paths.parishFunctions:
                return 'Funkcje użytkownikow';
            case paths.profile:
                return 'Dane do faktury';
            case paths.settings:
                return 'Ustawienia';
            default:
                return '';
        }
    };

    const payments = useSelector((state) => state.payments);
    const shouldShowAlert =
        payments?.cards &&
        moment(payments.parish && payments.parish.dueDate).diff(
            moment(),
            'days',
        ) < 8;

    useEffect(() => {
        if (!payments) {
            getSubscription();
        }
    }, []);

    return {
        logout,
        payments,
        shouldShowAlert,
        daysToDueDate: moment(payments?.parish?.dueDate).diff(moment(), 'days'),
        header: getHeader(),
    };
};
