import { IUser } from '@@types/models/User';
import createRequest from '@services/ApiService';

export default {
    getPayments: () => createRequest<IUser[]>(`/admin/payments`, 'GET'),
    getPaymentLink: () =>
        createRequest(`/admin/payments/get_payment_link`, 'GET'),
    addCard: (token: string) =>
        createRequest<any>(`/admin/payments/card`, 'POST', { token }),
    deleteCard: (token: string) =>
        createRequest<any>(`/admin/payments/card`, 'DELETE', { token }),
    sendEmail: (paymentId: string) =>
        createRequest<void>(`/admin/payments/${paymentId}/email`, 'POST'),
};
