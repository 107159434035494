import { IAlert, ITemplateEvent } from '@@types/models/Template';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultEventTemplate } from '../Event/useEvent';
import Alerts from '@@types/Alerts';
import { useParams } from 'react-router-dom';

export default () => {
    const [expanded, setExpanded] = useState(-1);
    const templates = useSelector((state) => state.templates?.templates) || [];
    const { dayString }: { dayString: string } = useParams();
    const [eventTemplatesValues, setEventTemplatesValues] = useState([
        getDefaultEventTemplate(dayString),
    ]);

    const addEventTemplate = () => {
        setEventTemplatesValues([
            ...eventTemplatesValues,
            getDefaultEventTemplate(dayString),
        ]);
    };

    useEffect(() => {
        setEventTemplatesValues(
            templates.map((template: ITemplateEvent) => ({
                name: template.name,
                color: template.color,
                _id: template._id,
                startHour: template.startHour,
                endHour: template.endHour,
                points: template.points,
                alerts: template.alerts.map((alert: IAlert) => ({
                    type: alert.type,
                    label: Alerts.find((el: IAlert) => el.type === alert.type)
                        ?.label as string,
                })),
                users: template.users,
            })),
        );
    }, [templates]);

    return {
        eventTemplatesValues,
        expanded,
        setExpanded,
        setEventTemplatesValues,
        addEventTemplate,
    };
};
