import { useDispatch, useSelector } from 'react-redux';

import {
    asyncGetParishUsers,
    asyncUpdateUserRole,
} from '@state/dashboard/actions';
import { useEffect, useState } from 'react';
import { __DEV__ } from '@shared/constants';
import { ROLES, UserIdWithRole } from '@@types/models/User';

export default () => {
    const dispatch = useDispatch();
    const getParishUsers = () =>
        dispatch(
            asyncGetParishUsers.request(true, {
                notification: __DEV__,
            }),
        );

    useEffect(() => {
        getParishUsers();
    }, []);

    const onChange = (user: UserIdWithRole) => {
        dispatch(
            asyncUpdateUserRole.request(user, {
                notification: __DEV__,
            }),
        );
    };

    const roles = {
        [ROLES.ADMIN]: 'Opiekun',
        [ROLES.SUPER_USER]: 'Super User',
        [ROLES.USER]: 'Użytkownik',
        [ROLES.PARENT]: 'Rodzic',
    };

    const users = useSelector((state) => state.dashboard?.users);
    return {
        users,
        roles,
        onChange,
    };
};
