import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { asyncAddCard } from '@state/payments/actions';
import { IAddCardRequest } from '@@types/models/Payments';
import { PAYU_ID, __DEV__ } from '@shared/constants';

const optionsForms = {
    cardIcon: true,
    style: {
        basic: {
            fontSize: '24px',
        },
    },
    placeholder: {
        number: '',
        date: 'MM/YY',
        cvv: '',
    },
    lang: 'pl',
};

export default (hideCard: Function) => {
    const dispatch = useDispatch();
    const [cardError, setCardError] = useState('');

    const handleAddCard = (payload: IAddCardRequest) =>
        dispatch(
            asyncAddCard.request(payload, {
                notification: __DEV__,
            }),
        );

    useEffect(() => {
        const PayU = (window as any).PayU;
        if (PayU) {
            const payuSdkForms = PayU(PAYU_ID);
            const secureForms = payuSdkForms.secureForms();
            const cardNumber = secureForms.add('number', optionsForms);
            const cardDate = secureForms.add('date', optionsForms);
            const cardCvv = secureForms.add('cvv', optionsForms);
            setTimeout(() => {
                cardNumber.render('#payu-card-number');
                cardDate.render('#payu-card-date');
                cardCvv.render('#payu-card-cvv');
                const tokenizeButton = document.getElementById(
                    'tokenizeButton',
                );
                const responseElement = document.getElementById(
                    'responseTokenize',
                );
                tokenizeButton?.addEventListener('click', () => {
                    if (responseElement) responseElement.innerText = '';

                    try {
                        payuSdkForms.tokenize('MULTI').then((result: any) => {
                            result.status === 'SUCCESS'
                                ? handleAddCard({ token: result.body.token })
                                : setCardError(
                                      result?.error?.messages[0]?.message,
                                  );
                            hideCard();
                        });
                    } catch (e) {
                        console.log(e);
                    }
                });
            }, 100);
        }
    });

    return {
        cardError,
    };
};
