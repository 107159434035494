import React from 'react';
import Dashboard from '@domains/DashboardDomain/Dashboard';
import { useStyles } from './DayTemplate.styled';
import Day from '@components/Template/Day/Day';
import useAddTemplate from './useDayTemplate';
import PolishDays from '@@types/PolishDays';

const DayTemplate = () => {
    const classes = useStyles();

    const { dayString } = useAddTemplate();
    return (
        <Dashboard>
            <div className={classes.root}>
                {dayString.toLowerCase() ===
                    PolishDays.monday.toLowerCase() && (
                    <Day dayString={dayString}></Day>
                )}
                {dayString.toLowerCase() ===
                    PolishDays.tuesday.toLowerCase() && (
                    <Day dayString={dayString}></Day>
                )}
                {dayString.toLowerCase() ===
                    PolishDays.wednesday.toLowerCase() && (
                    <Day dayString={dayString}></Day>
                )}
                {dayString.toLowerCase() ===
                    PolishDays.thursday.toLowerCase() && (
                    <Day dayString={dayString}></Day>
                )}
                {dayString.toLowerCase() ===
                    PolishDays.friday.toLowerCase() && (
                    <Day dayString={dayString}></Day>
                )}
                {dayString.toLowerCase() ===
                    PolishDays.saturday.toLowerCase() && (
                    <Day dayString={dayString}></Day>
                )}
                {dayString.toLowerCase() ===
                    PolishDays.sunday.toLowerCase() && (
                    <Day dayString={dayString}></Day>
                )}
            </div>
        </Dashboard>
    );
};

export default DayTemplate;
