import styled, { css } from 'styled-components';
import IconManager from '@components/_universal/IconManager/IconManager';
import { transparentize } from 'polished';
import { ButtonTypes } from '@@types/CommonTypes';

export const Wrapper = styled.div`
    display: inline-flex;
`;

export const primaryButton = css<{ disabled: boolean }>`
    color: ${({ theme }) => theme.colors.app_bg};
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;

    &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
        border-color: ${({ theme }) => theme.colors.hover};
    }

    ${({ disabled }) => disabled && primaryButtonDisabled}
`;

export const primaryButtonDisabled = css`
    background-color: ${({ theme }) => theme.colors.disable};
    border-color: ${({ theme }) => theme.colors.disable};
    opacity: 1;

    &:hover {
        background-color: ${({ theme }) => theme.colors.disable};
        border-color: ${({ theme }) => theme.colors.disable};
    }
`;

export const secondaryButton = css<{ disabled: boolean }>`
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;

    &:hover {
        background-color: ${({ theme }) =>
            transparentize(0.96, theme.colors.hover)};
        border-color: ${({ theme }) => theme.colors.hover};
    }

    ${({ disabled }) => disabled && secondaryButtonDisabled}
`;

export const secondaryButtonDisabled = css`
    border-color: ${({ theme }) => theme.colors.disable};
    opacity: 0.4;

    &:hover {
        border-color: ${({ theme }) => theme.colors.disable};
    }
`;

export const buttonWithIcon = css`
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
`;

export const Button = styled.button<{
    disabled: boolean;
    buttonType?: ButtonTypes;
    icon?: boolean;
    isChildren?: boolean;
}>`
    transition: all ${({ theme }) => theme.transitions.default}s;
    font-size: ${({ theme }) => theme.fontSizes.s}px;
    padding: ${({ icon, isChildren }) =>
        isChildren
            ? icon
                ? ' 11px 21px 11px 15px;'
                : '12px 32px'
            : '11px 30px'};
    line-height: 1.1;
    font-weight: ${({ theme }) => theme.fontWeight.black};
    letter-spacing: 0.04em;
    text-align: center;
    border-radius: ${({ theme }) => theme.borderRadius.default}px;
    cursor: pointer;
    min-width: 135px;
    ${({ buttonType }) =>
        buttonType === 'primary' ? primaryButton : secondaryButton};
    ${({ icon }) => icon && buttonWithIcon}

    &:hover, &:focus, &:active {
        outline: none;
    }
`;

export const StyledIconManager = styled(IconManager)`
    margin-right: 10px;
`;
