import { useSelector } from 'react-redux';
import { AsyncStatusRegExp } from '@@types/AsyncStatus';
import { getType } from 'typesafe-actions';

// TODO: add multiple actions
export default (action?: any) => {
    let loading =
        !action &&
        useSelector(
            ({ _status }) =>
                !!Object.values(_status).find(
                    (status) => status.type === 'PENDING',
                ),
        );

    const matches = action && AsyncStatusRegExp.exec(getType(action?.request));

    if (matches) {
        const [, prefix] = matches;

        loading = useSelector(
            ({ _status }) => _status[prefix]?.type === 'PENDING',
        );
    }

    return loading;
};
