import defaultTheme from '@shared/theme/default.theme';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: defaultTheme.fontSizes.xl,
        marginBottom: '20px',
        fontWeight: defaultTheme.fontWeight.bold,
    },
    subtitle: {
        marginTop: '20px',
        fontSize: defaultTheme.fontSizes.s,
        fontWeight: defaultTheme.fontWeight.bold,
    },
    button: {
        backgroundColor: defaultTheme.colors.primary,
        color: defaultTheme.colors.white,
        fontWeight: defaultTheme.fontWeight.bold,
        borderRadius: defaultTheme.borderRadius.button,
        '&:hover': {
            color: defaultTheme.colors.primary,
        },
    },
    disabledButton: {
        color: '#fff!important',
        opacity: '0.8',
    },
    form: {
        marginTop: '20px',
        display: 'flex',
        alignItems: 'flex-end',
    },
    chip: {
        margin: '0 10px 10px 0',
    },
}));
