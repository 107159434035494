export const palette = {
    mainColor: '#4F44FF',
    black: '#333435',
    gray: '#76808F',
    red: '#F44336',
    green: '#4CAF50',
    yellow: '#FFE27E',
    blue: '#009FE3',
    white: '#FFFFFF',
};

export const global = {
    fontSizes: {
        xs: 10,
        s: 12,
        m: 14,
        l: 16,
        xl: 18,
        xxl: 24,
        max: 36,
    },
    fontWeight: {
        light: 300,
        regular: 400,
        bold: 700,
        black: 900,
    },
    borderRadius: {
        checkbox: 2,
        default: 6,
        switch: 15,
        button: '25px',
    },
    transitions: {
        default: 0.5,
    },
};
