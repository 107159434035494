import React from 'react';
import * as Styled from './App.styled';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Theme } from '@@types/CommonTypes';
import themeVariant from '@utils/themeVariant';
import { GlobalStyled } from '@components/_global/Global.styled';
import RootRoutes from 'src/routes/RootRoutes';
import store from 'src/boot/configureStore';
import { resolveEnv } from '@utils/helpers';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
    return (
        <Provider store={store}>
            <BrowserRouter basename={resolveEnv('PUBLIC_URL')}>
                <ThemeProvider theme={themeVariant[Theme.DEFAULT]}>
                    <GlobalStyled />
                    <Styled.Wrapper>
                        <RootRoutes />
                    </Styled.Wrapper>
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
