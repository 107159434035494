import { createReducer } from 'typesafe-actions';
import { initialState } from './module';
import { asyncGetEventTemplates, asyncGetTemplate } from './actions';

export default createReducer(initialState)
    .handleAction([asyncGetTemplate.success], (state, action) => ({
        ...state,
        template: action.payload,
    }))
    .handleAction([asyncGetEventTemplates.success], (state, action) => ({
        ...state,
        templates: action.payload,
    }));
