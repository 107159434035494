import { createAsyncAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import {
    IAddCardRequest,
    IGetPaymentLink,
    ISubscription,
    IDeleteCardRequest,
} from '@@types/models/Payments';

export const asyncGetSubscription = createAsyncAction(
    '@payments/GET_SUBSCRIPTION:PENDING',
    '@payments/GET_SUBSCRIPTION:SUCCESS',
    '@payments/GET_SUBSCRIPTION:ERROR',
)<
    [undefined, ActionMeta],
    [ISubscription, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncGetPaymentLink = createAsyncAction(
    '@payments/GET_PAYMENT_LINK:PENDING',
    '@payments/GET_PAYMENT_LINK:SUCCESS',
    '@payments/GET_PAYMENT_LINK:ERROR',
)<
    [undefined, ActionMeta],
    [IGetPaymentLink, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncAddCard = createAsyncAction(
    '@payments/ADD_CARD:PENDING',
    '@payments/ADD_CARD:SUCCESS',
    '@payments/ADD_CARD:ERROR',
)<[IAddCardRequest, ActionMeta], [any, ActionMeta], [undefined, ActionMeta]>();

export const asyncDeleteCard = createAsyncAction(
    '@payments/DELETE_CARD:PENDING',
    '@payments/DELETE_CARD:SUCCESS',
    '@payments/DELETE_CARD:ERROR',
)<
    [IDeleteCardRequest, ActionMeta],
    [undefined, ActionMeta],
    [undefined, ActionMeta]
>();

export const asyncSendEmail = createAsyncAction(
    '@payments/SEND_EMAIL:PENDING',
    '@payments/SEND_EMAIL:SUCCESS',
    '@payments/SEND_EMAIL:ERROR',
)<[string, ActionMeta], [undefined, ActionMeta], [undefined, ActionMeta]>();
