import { PaymentButton } from '@domains/PaymentsDomain/Payments.styled';
import * as React from 'react';
import {
    Aside,
    CardContainer,
    Error,
    PayuCardForm,
    SecureFormContainer,
    CardDetails,
    Expiration,
    CVV,
} from './AddCard.styled';
import useAddCard from './useAddCard';

const AddCard = ({ hideCard }: any) => {
    const { cardError } = useAddCard(hideCard);
    return (
        <SecureFormContainer>
            <CardContainer>
                <Aside>Numer Karty</Aside>
                <PayuCardForm id="payu-card-number"></PayuCardForm>

                <CardDetails>
                    <Expiration>
                        <Aside>Ważna do</Aside>
                        <PayuCardForm
                            className="payu-card-form"
                            id="payu-card-date"
                        ></PayuCardForm>
                    </Expiration>

                    <CVV>
                        <Aside>CVV</Aside>
                        <PayuCardForm
                            className="payu-card-form"
                            id="payu-card-cvv"
                        ></PayuCardForm>
                    </CVV>
                </CardDetails>
            </CardContainer>
            <PaymentButton id="tokenizeButton">Zapisz</PaymentButton>
            <div id="responseTokenize"></div>
            {cardError && <Error>{cardError}</Error>}
        </SecureFormContainer>
    );
};

export default AddCard;
