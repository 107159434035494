import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useUsersList from './useUsersList';
import { IUser, ROLES } from '@@types/models/User';
import { makeStyles } from '@material-ui/core/styles';
import Dashboard from '@domains/DashboardDomain/Dashboard';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableContainer: {
        width: '100%',
    },
});

const UsersList = () => {
    const { users = [], roles, onChange } = useUsersList();
    const classes = useStyles();
    return (
        <Dashboard>
            <TableContainer
                component={Paper}
                className={classes.tableContainer}
            >
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="center">Imię</TableCell>
                            <TableCell align="center">Nazwisko</TableCell>
                            <TableCell align="center">Nr telefonu</TableCell>
                            <TableCell align="center">E-mail</TableCell>
                            <TableCell align="center">Rola</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users?.map((user: IUser, index: number) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {user.name}
                                </TableCell>
                                <TableCell align="center">
                                    {user.surname}
                                </TableCell>
                                <TableCell align="center">
                                    {user.phone}
                                </TableCell>
                                <TableCell align="center">
                                    {user.email}
                                </TableCell>
                                <TableCell align="center">
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={user.role}
                                        onChange={(e) => {
                                            onChange({
                                                _id: user._id,
                                                role: e.target.value as ROLES,
                                            });
                                        }}
                                    >
                                        {Object.entries(roles).map((role) => (
                                            <MenuItem value={role[0]}>
                                                {role[1]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Dashboard>
    );
};

export default UsersList;
