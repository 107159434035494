import { WEEK_DAYS } from './models/Template';

export default {
    poniedzialek: WEEK_DAYS.MONDAY,
    wtorek: WEEK_DAYS.TUESDAY,
    sroda: WEEK_DAYS.WEDNESDAY,
    czwartek: WEEK_DAYS.THURSDAY,
    piatek: WEEK_DAYS.FRIDAY,
    sobota: WEEK_DAYS.SATURDAY,
    niedziela: WEEK_DAYS.SUNDAY,
};
