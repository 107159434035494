import React, { useState } from 'react';
import * as Styled from './ErrorMessage.styled';
import { ButtonTypes, ColorType, IconName } from '@@types/CommonTypes';

interface IProps {
    message?: string;
}

const ErrorMessage: React.FC<IProps> = ({ message }) => {
    return <Styled.ErrorMessage>{message}</Styled.ErrorMessage>;
};

ErrorMessage.defaultProps = {};

export default ErrorMessage;
