import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { PayloadMetaAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import { __DEV__ } from '@shared/constants';
import { AxiosResponse } from 'axios';
import { ProfileData } from '@@types/models/ProfileData';
import profileApi from '@shared/api/profile/profileApi';

export function* addProfile(
    action: PayloadMetaAction<string, ProfileData, ActionMeta>,
) {
    try {
        yield call(profileApi.postProfile, action.payload);
        yield put(
            actions.asyncAddProfile.success(undefined, {
                message: 'Add Profile Data Successful',
                notification: __DEV__,
            }),
        );

        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        // action.meta.callbacks?.onError?.();
        yield put(
            actions.asyncAddProfile.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* getProfile(
    action: PayloadMetaAction<string, undefined, ActionMeta>,
) {
    try {
        const response: AxiosResponse<ProfileData> = yield call(
            profileApi.getProfile,
        );
        yield put(
            actions.asyncGetProfile.success(response.data, {
                message: 'Got Profile data',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncGetProfile.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

function* watchGetProfile() {
    yield takeLatest(actions.asyncGetProfile.request, getProfile);
}

function* watchAddProfile() {
    yield takeLatest(actions.asyncAddProfile.request, addProfile);
    yield takeLatest(actions.asyncAddProfile.success, getProfile);
}

export default function* () {
    yield all([watchGetProfile(), watchAddProfile()]);
}
