import React from 'react';
import useLoginForm from './useLoginForm';
import { TextField } from '@material-ui/core';
import LogoSvg from '@shared/svgs/logo';
import { LoginContainer, LoginButton } from './LoginForm.styled';
import { ErrorMessage } from '@components/_universal/Error/ErrorMessage.styled';
import { useHistory as useRouterHistory } from 'react-router-dom';
import paths from '../../../../shared/paths';

export default () => {
    const { register, isValid, onSubmit, signInError } = useLoginForm();
    const history = useRouterHistory<{ length: string }>();
    return (
        <LoginContainer>
            <LogoSvg />
            <form onSubmit={onSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    inputRef={register({ required: true })}
                    id="email"
                    label="E-mail"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    inputRef={register({ required: true })}
                    fullWidth
                    name="password"
                    label="Hasło"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <LoginButton disabled={!isValid}>Zaloguj się</LoginButton>
                <LoginButton
                    onPress={() => {
                        history.push(paths.signup);
                    }}
                >
                    Załóż konto i parafię
                </LoginButton>
                {signInError && (
                    <ErrorMessage>
                        Coś poszło nie tak. Sprawdź poprawność wprowadzonych
                        danych, jeśli błąd nadal będzie występował, skontaktuj
                        się z Administracją
                    </ErrorMessage>
                )}
            </form>
        </LoginContainer>
    );
};
