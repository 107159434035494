import React, { useState } from 'react';
import * as Styled from './Button.styled';
import { ButtonTypes, ColorType, IconName } from '@@types/CommonTypes';

interface IProps {
    children?: string;
    style?: React.CSSProperties;
    className?: string;
    onPress?: () => void;
    disabled?: boolean;
    buttonType?: ButtonTypes;
    icon?: {
        type: IconName;
        color?: ColorType[];
        activeColor?: ColorType[];
    };
    id?: string;
}

const Button: React.FC<IProps> = ({
    children,
    onPress,
    disabled,
    buttonType,
    icon,
    className,
    id,
}) => {
    const [active, setActive] = useState(false);
    return (
        <Styled.Button
            buttonType={buttonType}
            id={id}
            disabled={!!disabled}
            onClick={onPress}
            icon={!!icon?.type}
            isChildren={!!children}
            onMouseOver={() => setActive(true)}
            onMouseOut={() => setActive(false)}
            className={className}
        >
            {icon && (
                <Styled.StyledIconManager
                    fill={icon.color}
                    size={24}
                    name={icon.type}
                    activeFill={icon.activeColor}
                    isActive={active}
                />
            )}
            {children && children}
        </Styled.Button>
    );
};

Button.defaultProps = {
    buttonType: 'primary',
};

export default Button;
