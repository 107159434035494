import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { PayloadMetaAction } from 'typesafe-actions';
import { ActionMeta } from '@@types/Meta';
import { __DEV__ } from '@shared/constants';
import { AxiosResponse } from 'axios';
import { paymentsApi } from '@shared/api';
import {
    IAddCardRequest,
    IDeleteCardRequest,
    IGetPaymentLink,
    ISubscription,
} from '@@types/models/Payments';

export function* getPayments() {
    try {
        const response: AxiosResponse<ISubscription> = yield call(
            paymentsApi.getPayments,
        );
        yield put(
            actions.asyncGetSubscription.success(response.data, {
                message: 'Got Payments',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncGetSubscription.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* getPaymentLink() {
    try {
        const response: AxiosResponse<IGetPaymentLink> = yield call(
            paymentsApi.getPaymentLink,
        );
        yield put(
            actions.asyncGetPaymentLink.success(response.data, {
                message: 'Got Payment Link',
                notification: __DEV__,
            }),
        );
        window.location.replace(response.data.redirectUri);
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncGetSubscription.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* addCard(
    action: PayloadMetaAction<string, IAddCardRequest, ActionMeta>,
) {
    try {
        const response: AxiosResponse<any> = yield call(
            paymentsApi.addCard,
            action.payload.token,
        );
        if (response.data.redirectUri) {
            window.location.replace(response.data.redirectUri);
        }
        yield put(
            actions.asyncAddCard.success(undefined, {
                message: 'Added card',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncAddCard.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* deleteCard(
    action: PayloadMetaAction<string, IDeleteCardRequest, ActionMeta>,
) {
    try {
        yield call(paymentsApi.deleteCard, action.payload.token);
        yield put(
            actions.asyncDeleteCard.success(undefined, {
                message: 'Deleted Card',
                notification: __DEV__,
            }),
        );
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncDeleteCard.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

export function* sendEmail(
    action: PayloadMetaAction<string, string, ActionMeta>,
) {
    try {
        yield call(paymentsApi.sendEmail, action.payload);
        yield put(
            actions.asyncSendEmail.success(undefined, {
                message: 'Email sent',
                notification: __DEV__,
            }),
        );
        action.meta.callbacks?.onSuccess?.();
    } catch ({ data: { message, errors, error }, status }) {
        yield put(
            actions.asyncSendEmail.failure(undefined, {
                message: message || error,
                code: status,
                notification: __DEV__,
                errors,
            }),
        );
    }
}

function* watchGetSubscription() {
    yield takeLatest(actions.asyncGetSubscription.request, getPayments);
}

function* watchGetPaymentLink() {
    yield takeLatest(actions.asyncGetPaymentLink.request, getPaymentLink);
}

function* watchSendEmail() {
    yield takeLatest(actions.asyncSendEmail.request, sendEmail);
}

function* watchAddCard() {
    yield takeLatest(actions.asyncAddCard.request, addCard);
    yield takeLatest(actions.asyncAddCard.success, getPayments);
}

function* watchDeleteCard() {
    yield takeLatest(actions.asyncDeleteCard.request, deleteCard);
    yield takeLatest(actions.asyncDeleteCard.success, getPayments);
}

export default function* () {
    yield all([
        watchGetSubscription(),
        watchGetPaymentLink(),
        watchAddCard(),
        watchDeleteCard(),
        watchSendEmail(),
    ]);
}
